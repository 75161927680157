import { DateTime } from 'luxon';

import { DEFAULT_DATE_FORMAT } from 'commons/constants';

export const formatDate = (
  date: string | Date,
  format: string = DEFAULT_DATE_FORMAT,
): string => {
  if (typeof date === 'string') {
    return DateTime.fromISO(date).toFormat(format);
  }
  return DateTime.fromJSDate(date).toFormat(format);
};
