import React from 'react';

import { Dialog } from '@mui/material';

import { IBaseAssignBasketModalProps } from '../../commons/components/assign-basket-modal/assignBasketModal.interface';

import * as S from './RemoveSODialog.styels';

type IRemoveSODialog = IBaseAssignBasketModalProps;

const RemoveSODialog: React.FC<IRemoveSODialog> = ({
  task,
  onClose,
  openModal,
  onConfirm,
}) => {
  // immediate return if null
  if (!task) {
    onClose();
    return <div />;
  }

  return (
    <Dialog onClose={onClose} open={openModal}>
      <S.TitleWrapper>
        <S.Title>ASSIGN BASKET TO:</S.Title>
      </S.TitleWrapper>

      <S.Text>
        Are you sure to remove SO <b>{task.po_number}</b> from your list?
      </S.Text>

      {/* Footer */}
      <S.FooterWrapper>
        <S.CancelButton onClick={onClose}>NO</S.CancelButton>
        <S.OKButton onClick={() => onConfirm(task)}>YES</S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};

export default RemoveSODialog;
