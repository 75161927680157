/* eslint-disable class-methods-use-this */
import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import { IAlertNotificationResponse } from './interfaces';

class SwipeRxWmsApiAlertNotification extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.ALERT_NOTIFICATION);
  }

  async getAlertNotification(): Promise<IAlertNotificationResponse | null> {
    try {
      const result = await this.get(`${this.apiResource}/`);
      return result.data as IAlertNotificationResponse;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }
}

export const swipeRxWmsApiAlertNotification =
  new SwipeRxWmsApiAlertNotification();
