import React from 'react';

import { Grid, Button, Popper, Grow, Paper, Typography } from '@mui/material';

import { ChooseActionBtnProps } from 'commons/types/listPageData.interface';

export const ChooseActionBtn: React.FC<
  React.PropsWithChildren<ChooseActionBtnProps>
> = (params) => {
  const {
    children,
    selectedData = [],
    anchorEl,
    openMenu,
    handleClickMenu,
    dataName,
  }: React.PropsWithChildren<ChooseActionBtnProps> = params;

  return (
    <Grid item xs={3} alignContent="center" alignItems="center">
      <Button
        onClick={handleClickMenu}
        disabled={selectedData.length === 0}
        variant="outlined"
        fullWidth
      >
        -- Choose Action --
      </Button>
      {selectedData.length > 0 && (
        <Typography variant="body2" align="center">
          <b>
            {selectedData.length} {dataName || 'row'}(s)
          </b>{' '}
          are selected
        </Typography>
      )}
      <Popper
        open={openMenu || false}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps, placement }): React.ReactNode => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>{children}</Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};
