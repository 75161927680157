/* eslint-disable react/no-unstable-nested-components */

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectUser } from '../../redux-stores/features/authSlice';

const PrivateRoute = ({ roles }) => {
  const user = useSelector(selectUser);

  const checkRoles = () => {
    if (user?.isAuthenticated) {
      return roles.some((r) => {
        const resource = user.role === r;

        return resource;
      });
    }
    return false;
  };

  // eslint-disable-next-line react/no-unknown-property
  return checkRoles() ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
