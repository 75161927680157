import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class LoginPageStyle extends CommonComponentStyle {
  Icon = styled.img`
    margin-top: 6px;
    height: 45px;
  `;

  Text = styled.p`
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-left: 8px;
    text-align: center;
  `;

  Btn = styled.button`
    border: none;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    color: Blue;
    display: inline-block;
  `;
}

export default new LoginPageStyle();
