import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Brightness1, Done } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { ReplenishTaskProgressEnum } from 'commons/enums';
import {
  ReplenishBinRequestedWithReplenishedQuantity,
  ReplenishTask,
} from 'commons/types';
import { getReplenishTasksAction } from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { destinationConfig } from './config';
import S from './ReplenishListPage.styles';

export const ReplenishListDestinationPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { assignedTasks } = useSelector(
    (state: RootReducerInterface) => state.replenishTask,
  );

  const [filter, setFilter] = useState<string>('');
  const [displayedTasks, setDisplayedTasks] =
    useState<ReplenishTask[]>(assignedTasks);

  useEffect(() => {
    dispatch(getReplenishTasksAction({}));
  }, [dispatch]);

  const _setDisplayedTaskWithAdditional = (tasks: ReplenishTask[]) => {
    const destinationTasks: ReplenishTask[] = [];
    tasks.forEach((assignedTask) => {
      const task = structuredClone(assignedTask);
      const replenishSourceProgress = task.source_bins.reduce(
        (sum, sourceBin) => sum + sourceBin.quantity,
        0,
      );
      if (replenishSourceProgress !== 0) {
        destinationTasks.push(task);
      }
    });

    const unfulfilledTasks: ReplenishTask[] = [];
    const partiallyFulfilledTasks: ReplenishTask[] = [];
    const fulfilledTasks: ReplenishTask[] = [];

    destinationTasks.forEach((assignedTask) => {
      const task = structuredClone(assignedTask);

      task.quantity_picked = task.source_bins.reduce(
        (sum, sourceBin) => sum + sourceBin.quantity,
        0,
      );

      task.quantity_replenished = task.destination_bins.reduce(
        (sum, destinationBin) => sum + destinationBin.quantity,
        0,
      );

      if (task.quantity_replenished === 0) {
        task.progress = ReplenishTaskProgressEnum.UNFULFILLED;
        unfulfilledTasks.push(task);
      } else if (task.quantity_replenished === task.quantity) {
        task.progress = ReplenishTaskProgressEnum.FULFILLED;
        partiallyFulfilledTasks.push(task);
      } else {
        task.progress = ReplenishTaskProgressEnum.PARTIALLY_FULFILLED;
        fulfilledTasks.push(task);
      }
    });

    setDisplayedTasks(
      unfulfilledTasks.concat(partiallyFulfilledTasks, fulfilledTasks),
    );
  };

  useMemo(() => {
    _setDisplayedTaskWithAdditional(assignedTasks);
  }, [assignedTasks]);

  const onFilterChange = (value: string): void => {
    setFilter(value);
    if (value === '') {
      _setDisplayedTaskWithAdditional(assignedTasks);
    } else {
      const filteredTasks = assignedTasks.filter((task) => {
        if (task.sku_code.includes(value)) {
          return true;
        }
        return false;
      });
      _setDisplayedTaskWithAdditional(filteredTasks);
    }
  };

  const handleBackToSourcePage = (): void => {
    navigate('/replenish/list/source');
  };

  const renderList = (replenishList: ReplenishTask[]) =>
    replenishList.map((data) => {
      const { progress } = data;
      const colorProgress =
        progress === ReplenishTaskProgressEnum.UNFULFILLED
          ? S.colors.PROGRESS_ICON_COLOR[0]
          : progress === ReplenishTaskProgressEnum.FULFILLED
          ? S.colors.PROGRESS_ICON_COLOR[1]
          : S.colors.PROGRESS_ICON_COLOR[2];
      const skuButtonText =
        progress === ReplenishTaskProgressEnum.UNFULFILLED ? 'START' : 'EDIT';

      const destinationDisplay: Record<
        string, // sku_code
        ReplenishBinRequestedWithReplenishedQuantity
      > = {};
      /* fill based on destination request */
      data.destination_bin_requested.forEach((bin) => {
        const binNumber = bin.bin || 'user-request';
        destinationDisplay[binNumber] = {
          ...bin,
          replenished_quantity: data.destination_bins
            .filter((dest) => dest.bin === binNumber)
            .reduce((total, dest) => total + dest.quantity, 0),
        };
      });
      /* fill additional replenishment */
      data.destination_bins.forEach((bin) => {
        /* only set if not already set in previous step -- to prevent redundancy */
        if (!destinationDisplay[bin.bin]) {
          destinationDisplay[bin.bin] = {
            warehouse_id: data.warehouse_id,
            requested_quantity: 0,
            replenished_quantity: data.destination_bins
              .filter((dest) => dest.bin === bin.bin)
              .reduce((total, dest) => total + dest.quantity, 0),
            bin: bin.bin,
            bin_type: bin.bin_type,
          };
        }
      });

      return (
        <Grid container>
          <S.SKUGridWrapper container>
            <S.SKUGrid item xs={8}>
              SKU {data.sku_code}
              <S.ProgressSpan>
                <Brightness1 style={{ fontSize: 11, color: colorProgress }} />{' '}
                {data.quantity_replenished ?? 0}/{data.quantity_picked}
              </S.ProgressSpan>
            </S.SKUGrid>
            <S.SKUButtonGrid item xs={4}>
              <S.SecondaryButton
                onClick={() => {
                  navigate(`/replenish/task/destination/${data.sku_code}`);
                }}
              >
                {skuButtonText}
              </S.SecondaryButton>
            </S.SKUButtonGrid>
          </S.SKUGridWrapper>
          <S.TableInfoGridWrapper container>
            <S.TableInfoGrid item xs={12}>
              <TableComponent
                config={destinationConfig}
                data={Object.values(destinationDisplay)}
              />
            </S.TableInfoGrid>
          </S.TableInfoGridWrapper>
        </Grid>
      );
    });

  return (
    <div>
      <Header title="Replenish List" prevPageHandler={handleBackToSourcePage} />
      {/* Content Section */}
      <S.ContentWrapper>
        <S.StepGridWrapper container>
          <S.StepGrid item xs={5}>
            <span>
              <Done sx={{ fontSize: 14 }} />
            </span>
            Source Bin
          </S.StepGrid>
          <S.ConnectorGrid item xs={2}>
            <S.Connector />
          </S.ConnectorGrid>
          <S.StepGrid item xs={5} className="current-step">
            <span>2</span> Destination Bin
          </S.StepGrid>
        </S.StepGridWrapper>
        <S.SearchBar
          placeholder="SKU Number or Scan"
          variant="outlined"
          value={filter}
          onChange={(event) => onFilterChange(event.target.value)}
        />

        {renderList(displayedTasks)}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.FooterButtonGridWrapper container>
          <S.FooterButtonGrid item xs={6}>
            <S.SecondaryButton
              onClick={() => navigate('/replenish/list/source')}
            >
              BACK TO SOURCE
            </S.SecondaryButton>
          </S.FooterButtonGrid>
          <S.FooterButtonGrid item xs={6}>
            <S.PrimaryButton
              disabled={false}
              onClick={() => navigate('/replenish/summary')}
            >
              DONE
            </S.PrimaryButton>
          </S.FooterButtonGrid>
        </S.FooterButtonGridWrapper>
      </S.FooterWrapper>
    </div>
  );
};
