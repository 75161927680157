import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox } from '@mui/material';

import * as S from 'commons/components/Table/Table.styles';
import { LIGHT_GRAY, PRIMARY_RED, PRIMARY_YELLOW } from 'commons/styles/colors';
import { DataTableProps } from 'commons/types/basketInfoData.interface';
import { BasketProductDetails } from 'commons/types/packingTask.interface';
import { checkItem } from 'redux-stores/reducers/packingTaskReducer';
import { AppDispatch } from 'redux-stores/store';

import { CheckBasketStyle as SB } from '../CheckBasket.styles';

import { BasketInfoDataTable } from './BasketInfoDataTable.component';
import EditQtyModal from './EditQtyModal.component';

const Row: React.FC<{
  data: BasketProductDetails;
  onCheckboxClick: (itemIndex: number) => void;
  itemIndex: number;
  basketNumber: number;
  getTotalQtyBySkuCode: (skuCode: string) => number;
  isDisabled: boolean;
}> = ({
  data,
  onCheckboxClick,
  itemIndex,
  basketNumber,
  getTotalQtyBySkuCode,
  isDisabled,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenDialog = (): void => {
    setOpen(true);
  };

  const handleCloseDialog = (): void => {
    setOpen(false);
  };

  return (
    <>
      <S.TableRow>
        <S.TableColumn>
          <SB.BoldText>{data.sku_code}</SB.BoldText>
          {data.is_modified ? <SB.GreenText>Modified</SB.GreenText> : null}
        </S.TableColumn>
        <S.TableColumn>{data.name}</S.TableColumn>
        <S.TableColumn>{data.uom}</S.TableColumn>
        <S.TableColumn style={{ textAlign: 'center' }}>
          {data.quantity_ordered}
        </S.TableColumn>
        <S.TableColumn style={{ textAlign: 'center' }}>
          <SB.QtyPicked
            style={{
              borderColor:
                getTotalQtyBySkuCode(data.sku_code) > data.quantity_ordered ||
                data.total_qty_picked === 0
                  ? PRIMARY_RED
                  : getTotalQtyBySkuCode(data.sku_code) < data.quantity_ordered
                  ? PRIMARY_YELLOW
                  : LIGHT_GRAY,
            }}
            onClick={() => handleOpenDialog()}
            disabled={isDisabled}
          >
            {data.total_qty_picked}
          </SB.QtyPicked>
        </S.TableColumn>
        <S.TableColumn style={{ textAlign: 'center' }}>
          <Checkbox
            onClick={() => onCheckboxClick(itemIndex)}
            checked={data.check_passed}
            disabled={
              getTotalQtyBySkuCode(data.sku_code) > data.quantity_ordered ||
              isDisabled
            }
          />
          <SB.WarningText
            style={{
              visibility: data.total_qty_picked === 0 ? 'visible' : 'hidden',
            }}
          >
            (Please delete/modify on netsuite)
          </SB.WarningText>
        </S.TableColumn>
      </S.TableRow>
      <EditQtyModal
        openModal={open}
        onClose={() => handleCloseDialog()}
        data={data}
        basketNumber={basketNumber}
      />
    </>
  );
};

export const BasketInfoTable: React.FC<DataTableProps> = (
  params,
): React.ReactNode => {
  const {
    data = [],
    rowTitles,
    basketIndex,
    basketNumber,
    getTotalQtyBySkuCode,
    isDisabled,
  }: DataTableProps = params;

  const dispatch = useDispatch<AppDispatch>();

  const handleCheckPassed = (itemIndex: number): void => {
    dispatch(checkItem({ basketIndex, itemIndex }));
  };

  return (
    <BasketInfoDataTable rowTitles={rowTitles}>
      <S.TableBody>
        {data.map((item, index) => (
          <Row
            data={item}
            key={item.sku_code}
            onCheckboxClick={handleCheckPassed}
            itemIndex={index}
            getTotalQtyBySkuCode={getTotalQtyBySkuCode}
            basketNumber={basketNumber}
            isDisabled={isDisabled}
          />
        ))}
      </S.TableBody>
    </BasketInfoDataTable>
  );
};
