/* eslint-disable class-methods-use-this */
import { PickingTask } from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import { IAssignBasketPayload, IBasketRecommendation } from './interfaces';

class SwipeRxWmsApiAssignBasket extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.ASSIGN_BASKET);
  }

  async assignBasketsToAPI(
    payload: IAssignBasketPayload,
  ): Promise<PickingTask> {
    try {
      const result = await this.post(`${this.apiResource}`, payload);
      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getBasketRecommendation(
    poNumber: string,
  ): Promise<IBasketRecommendation | null> {
    try {
      const result = await this.get(
        `${this.apiResource}/basket-recommendation`,
        { poNumber },
      );
      return result.data as IBasketRecommendation;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }
}

export const swipeRxWmsApiAssignBasket = new SwipeRxWmsApiAssignBasket();
