import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class AssignSKUPageStyle extends CommonComponentStyle {
  SubtitleWrapper = styled(Grid)`
    padding: 8px 0 16px 0;
  `;

  Subtitle = styled(Grid)`
    font-size: 14px;
    font-weight: 700;
    padding-top: 0.5rem;
  `;

  GridWrapper = styled(Grid)`
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  SkuNumberGridWrapper = styled(Grid)`
    background: ${this.colors.LIGHTER_GRAY};
    padding: 8px;
  `;

  SkuInfoGrid = styled(Grid)`
    font-size: 14px;
    font-weight: 700;

    display: inline-block;
    vertical-align: middle;

    &.priority {
      text-align: right;
    }
  `;

  SkuTypeInfoGrid = styled(Grid)`
    background: ${this.colors.LIGHT_GRAY};
    color: ${this.colors.PRIMARY_GRAY};
    max-width: fit-content;
    max-width: -moz-fit-content;
    float: right;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 10px;
    margin-top: 8px;

    &.picker_request {
      background: ${this.colors.LIGHTER_ORANGE};
      color: ${this.colors.PRIMARY_ORANGE};
    }
  `;

  SkuMinBinQtyGrid = styled.p`
    font-size: 12px;
  `;

  AddSKUButtonWrapper = styled.div`
    text-align: center;
    width: 100%;
    margin-top: 8px;
  `;

  BinInfoGridWrapper = styled(Grid)`
    margin-bottom: 8px;
    padding-left: 8px;
  `;

  BinInfoGrid = styled(Grid)`
    font-size: 14px;
    color: #737373;
    font-weight: 700;
    padding: 8px 0;

    p {
      font-size: 16;
      font-weight: 400;
      color: black;
    }
  `;
}

export default new AssignSKUPageStyle();
