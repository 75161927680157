import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { LIGHT_GRAY, PRIMARY_GRAY } from 'commons/styles/colors';

class PackPrintPageStyle extends CommonComponentStyle {
  MainInfoWrapper = styled(Grid)`
    font-size: 12px;
    margin: 8px 0 24px 0;
  `;

  MainInfoItem = styled(Grid)`
    p {
      &.info-title {
        color: ${PRIMARY_GRAY};
      }
    }
  `;

  PriorityText = styled.span`
    background: ${this.colors.LIGHT_YELLOW};
    color: ${this.colors.PRIMARY_ORANGE};
    border-radius: 8px;
    padding: 3px 10px;
  `;

  PrintInfoWrapper = styled(Grid)`
    border-top: 1px solid ${LIGHT_GRAY};
    padding: 16px 0;
  `;

  PrintInfoItem = styled(Grid)`
    font-size: 14px;
    &.align-right {
      text-align: right;
    }
    &.inline-flex {
      display: inline-flex;
      padding-top: 8px;
    }
    span {
      padding-top: 8px;
      margin-left: 8px;
    }
    p {
      margin-left: 40px;
    }
  `;
}

export default new PackPrintPageStyle();
