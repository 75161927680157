import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import {
  bulkPrioritizeOrderPick,
  bulkRetryUpdateFailedOrder,
  prioritizeOrderPick,
  retryUpdateFailedOrder,
} from 'redux-stores/actions';

import {
  IOpenBulkDialogData,
  IOpenDialogData,
} from '../type/open-dialog-state.interface';

export const OpenDialogConfig: Record<string, IOpenDialogData | null> = {
  prioritize: {
    title: 'Prioritize Task',
    content: (poNumber: string): string => {
      return `Are you sure to prioritize ${poNumber}?`;
    },
    onConfirm: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      poNumber: string,
      location?: number,
    ): void => {
      dispatch(prioritizeOrderPick({ poNumber, location }));
    },
  },
  retry: {
    title: 'Submit Retry Update Failed Task',
    content: (poNumber: string): string => {
      return `Are you sure to submit retry ${poNumber}?`;
    },
    onConfirm: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      poNumber: string,
      location?: number,
    ): void => {
      dispatch(retryUpdateFailedOrder({ poNumber, location }));
    },
  },
};

export const OpenBulkDialogConfig: Record<string, IOpenBulkDialogData | null> =
  {
    Prioritize: {
      title: 'Prioritize Task',
      content: (poNumbers: string[]): string => {
        return `Are you sure to prioritize following POs: \n\n ${poNumbers.join(
          '\n',
        )}?`;
      },
      onConfirm: (
        dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
        poNumbers: string[],
        location?: number,
      ): void => {
        dispatch(bulkPrioritizeOrderPick({ poNumbers, location }));
      },
    },
    Retry: {
      title: 'Submit Retry Update Failed Task',
      content: (poNumbers: string[]): string => {
        return `Are you sure to submit update retry for following POs: \n\n ${poNumbers.join(
          '\n',
        )}?`;
      },
      onConfirm: (
        dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
        poNumbers: string[],
        location?: number,
      ): void => {
        dispatch(bulkRetryUpdateFailedOrder({ poNumbers, location }));
      },
    },
  };
