import { createTheme } from '@mui/material';

import {
  LIGHT_BLUE,
  LIGHT_GRAY,
  PRIMARY_BLUE,
  PRIMARY_GRAY,
  PRIMARY_GREEN,
  PRIMARY_RED,
  PRIMARY_YELLOW,
} from 'commons/styles/colors';

export default createTheme({
  palette: {
    primary: {
      main: PRIMARY_BLUE,
      light: LIGHT_BLUE,
    },
    secondary: {
      main: PRIMARY_GREEN,
    },
    info: {
      main: PRIMARY_GRAY,
      light: LIGHT_GRAY,
    },
    success: {
      main: PRIMARY_GREEN,
    },
    warning: {
      main: PRIMARY_YELLOW,
    },
    error: {
      main: PRIMARY_RED,
    },
  },
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: 14,
    button: {
      fontFamily: 'Nunito Sans, sans-serif',
      textTransform: 'none',
      fontSize: 14,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8px',
          fontSize: 14,
        },
      },
    },
  },
});
