import React from 'react';

import { ReplenishBinRequested } from 'commons/types';
import { TableConfig } from 'commons/types/tableConfig.interface';

const formatText = (text: string, alignCenter = false): React.JSX.Element => {
  return (
    <div style={{ textAlign: alignCenter ? 'center' : 'left' }}>{text}</div>
  );
};

export const config: TableConfig = [
  {
    headerElement: formatText('BIN', true),
    element: (bin: ReplenishBinRequested): React.ReactElement => {
      return formatText(bin.bin ?? '', true);
    },
  },
  {
    header: 'Retail QTY',
    element: (bin: ReplenishBinRequested): React.ReactElement => {
      return formatText((bin.current_quantity || '').toString());
    },
  },
];
