import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Add, Close } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

import { Header } from 'commons/components/Header';
import { OrderStatusEnum } from 'commons/enums';
import { PartialPickingTask, PickingTask } from 'commons/types';
import {
  actionAddAssignedTask,
  assignBasket,
  getPickingTasksAction,
  actionRemoveAssignedTask,
  actionSetAssignedTask,
  actionStartPickingTask,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { AssignBasketModal } from '../commons/components';

import S from './AssignTaskPage.styles';
import RemoveSODialog from './components/RemoveSODialog.modal';

const AssignSOPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { assignedTasks } = useSelector(
    (state: RootReducerInterface) => state.pickingTask,
  );
  const [openAssignBasketModal, setOpenAssignBasketModal] =
    useState<boolean>(false);
  const [taskModal, setTaskModal] = useState<PickingTask | null>(null);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [removedTask, setRemovedTask] = useState<PickingTask | null>(null);

  useEffect(() => {
    dispatch(getPickingTasksAction());
  }, [dispatch]);

  const onModalClose = (): void => setOpenAssignBasketModal(false);
  const onModalOpen = (task: PickingTask): void => {
    setTaskModal(task);
    setOpenAssignBasketModal(true);
  };
  const assignBasketToSO = (task: PartialPickingTask): void => {
    dispatch(assignBasket({ task, updateState: true }));
    setOpenAssignBasketModal(false);
  };

  const assignSO = (): void => {
    if (assignedTasks.length === 0) {
      dispatch(actionSetAssignedTask());
    } else {
      dispatch(actionAddAssignedTask());
    }
  };

  const startPicking = async () =>
    dispatch(actionStartPickingTask({ navigate, tasks: assignedTasks }));

  const onRemoveModalOpen = (task: PickingTask): void => {
    setOpenRemoveModal(true);
    setRemovedTask(task);
  };
  const onRemoveModalClose = (): void => setOpenRemoveModal(false);
  const removeSO = (task: PartialPickingTask): void => {
    dispatch(actionRemoveAssignedTask(task.po_number));
    setOpenRemoveModal(false);
  };

  const validateAssignment = (): boolean => {
    if (assignedTasks.length === 0) return false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < assignedTasks.length; i++) {
      if (assignedTasks[i].baskets.length === 0) return false;
    }

    return true;
  };

  const renderBasketList = (tasks: PickingTask[]): React.JSX.Element[] =>
    tasks.map((task) => (
      <S.GridWrapper container>
        {task?.status === OrderStatusEnum.QUEUE && (
          <Grid item xs={1}>
            <IconButton
              style={{ padding: 0, marginTop: 28 }}
              onClick={() => onRemoveModalOpen(task)}
            >
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        )}

        <Grid item xs={task?.status === OrderStatusEnum.QUEUE ? 11 : 12}>
          <S.PoNumberGridWrapper container>
            <S.PoInfoGrid item xs={9}>
              {task.is_high_priority && '!'} {task.po_number}
            </S.PoInfoGrid>
            <S.PoInfoGrid className="priority" item xs={1}>
              {task.priority}
            </S.PoInfoGrid>
            <S.ButtonGrid item xs={2} onClick={() => onModalOpen(task)}>
              <S.AssignBasketButton>
                <S.AssignBasketIcon
                  src="/assets/cart-icon.svg"
                  alt="cart-icon"
                />
              </S.AssignBasketButton>
            </S.ButtonGrid>
            <S.PoInfoGrid item xs={6}>
              Total QTY:{' '}
              {task.items.reduce((total, item) => item.quantity + total, 0)}
            </S.PoInfoGrid>
            <S.PoInfoGrid item xs={6} style={{ textAlign: 'right' }}>
              Total SKUs: {task.items.length}
            </S.PoInfoGrid>
          </S.PoNumberGridWrapper>
          <S.BasketInfoGridWrapper container>
            <S.BasketInfoGrid item xs={12}>
              Basket(s):{' '}
              <span>
                {task.baskets?.length
                  ? task.baskets.join(', ')
                  : 'No assigned basket.'}
              </span>
            </S.BasketInfoGrid>
          </S.BasketInfoGridWrapper>
        </Grid>
      </S.GridWrapper>
    ));

  return (
    <div>
      <Header
        title="Picking"
        prevPageHandler={() => {
          navigate('/home/outbound');
        }}
      />
      {/* Content Section */}
      <S.ContentWrapper>
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12}>
            Assign SO
          </S.Subtitle>
        </S.SubtitleWrapper>

        {renderBasketList(assignedTasks)}

        <S.AddSOButtonWrapper>
          <S.SecondaryButton onClick={assignSO}>
            <Add />{' '}
            {assignedTasks.length > 0 ? 'ASSIGN ANOTHER SO' : 'ASSIGN SO'}
          </S.SecondaryButton>
        </S.AddSOButtonWrapper>
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton
          disabled={!validateAssignment()}
          onClick={startPicking}
        >
          START PICKING
        </S.PrimaryButton>
      </S.FooterWrapper>

      {/* Modal */}
      {openAssignBasketModal && (
        <AssignBasketModal
          task={taskModal}
          onClose={onModalClose}
          openModal={openAssignBasketModal}
          onConfirm={assignBasketToSO}
          removeDisabled={taskModal?.status !== OrderStatusEnum.QUEUE}
        />
      )}
      {openRemoveModal && (
        <RemoveSODialog
          task={removedTask}
          onClose={onRemoveModalClose}
          openModal={openRemoveModal}
          onConfirm={removeSO}
        />
      )}
    </div>
  );
};

export default AssignSOPage;
