import React from 'react';

import {
  CheckCircleOutline,
  ExpandMore,
  KeyboardDoubleArrowRightOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  capitalize,
} from '@mui/material';

import * as colors from 'commons/styles/colors';
import { ReplenishTaskWithSummary } from 'commons/types';

import { ReplenishSummaryStyle as S } from '../ReplenishSummary.style';

import { ReplenishBinList } from './replenish-bin-list.component';

interface ReplenishSkuListProps {
  replenishTask: ReplenishTaskWithSummary[];
  headerText: string;
  headerIcon?: React.JSX.Element;
  headerColor?: string;
  defaultExpanded?: boolean;
}

export const ReplenishSkuList: React.FC<ReplenishSkuListProps> = ({
  replenishTask,
  headerText,
  headerIcon,
  headerColor,
  defaultExpanded = false,
}) => {
  return (
    <>
      <S.HeaderWrapper>
        <S.Header color={headerColor || colors.PRIMARY_GREEN}>
          {headerIcon || <CheckCircleOutline />} {capitalize(headerText)}
        </S.Header>
        <S.SubHeader>Below is the summary of the task.</S.SubHeader>
      </S.HeaderWrapper>

      {replenishTask.map((task, i) => {
        const replenishQtyColor =
          task.totalQty.source === task.totalQty.destination
            ? colors.PRIMARY_BLACK
            : colors.PRIMARY_YELLOW;
        return (
          <Accordion
            defaultExpanded={
              defaultExpanded || task.totalBinQtyMissing.length > 0
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${i}-content`}
              id={`panel${i}-header`}
            >
              <Grid container style={{ fontWeight: 700 }}>
                <Grid item xs={5}>
                  {task.sku_code}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    textAlign: 'right',
                    verticalAlign: 'middle',
                    color: '#737373',
                  }}
                >
                  Replenish:{' '}
                  <span style={{ color: replenishQtyColor }}>
                    {task.totalQty.source}{' '}
                    <KeyboardDoubleArrowRightOutlined
                      style={{
                        verticalAlign: 'text-bottom',
                      }}
                    />{' '}
                    {task.totalQty.destination}
                  </span>
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ReplenishBinList
                sources={task.source_bins}
                destination={task.destination_bins}
                remainsQty={task.batchRemainsQty}
                totalBinQtyMissing={task.totalBinQtyMissing}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
