import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos } from '@mui/icons-material';
import { Grid } from '@mui/material';

import UserInfoHeader from './components/UserInfoHeader.component';
import S from './HomePage.styles';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <UserInfoHeader />
      <S.MenuOption onClick={() => navigate('/home/outbound')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon src="/assets/outbound-icon.svg" alt="outbound-icon" />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Outbound</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 14, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>

      <S.MenuOption onClick={() => navigate('/home/inbound')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon src="/assets/inbound-icon.svg" alt="inbound-icon" />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Inbound</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 14, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>

      {/* Menu Options */}
      {/* Dashboard */}
      <S.MenuOption onClick={(): void => navigate('/dashboard')}>
        <Grid container>
          <Grid item xs={2}>
            {/* Placeholder icon due to unavailability */}
            <S.MenuIcon src="/assets/checking-icon.svg" alt="dashboard-icon" />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Dashboard</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 18, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>
    </div>
  );
};

export default HomePage;
