import { Box, colors } from '@mui/material';
import styled from 'styled-components';

class SidebarStyle {
  Sidebar = styled(Box)`
    position: sticky;
    top: 0;
  `;

  SidebarWrapper = styled.div`
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 8px;
    min-height: 100%;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  `;

  SidebarHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.8rem 1rem;
    background-color: #f5f5f5;
    border-radius: 0.25rem;
  `;

  SidebarHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `;

  SidebarHeaderTitle = styled.p`
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
  `;

  SidebarHeaderSubtitle = styled.p`
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  `;

  SidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media only screen and (max-width: 768px) {
      height: 0;
      overflow: hidden;
      opacity: 0;
    }
  `;

  // TODO: integrate MUI to this somehow, maybe use MUI's ButtonBase
  SidebarMenuItem = styled.button`
    width: 100%;
    text-align: left;
    padding: 0.8rem 1rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: ${(props): string =>
      props.color === 'primary' ? `${colors.blue[600]}20` : '#fff'};
    color: ${(props): string =>
      props.color === 'primary' ? colors.blue[600] : '#000'};
    cursor: pointer;
    transition: all 0.25s;
    &:hover {
      background-color: ${colors.blue[600]}3a;
      color: ${colors.blue[600]};
    }
    &:focus {
      outline: none;
    }

    @media only screen and (max-width: 768px) {
      user-select: none;
    }
  `;
}

export const S = new SidebarStyle();
