/* eslint-disable react/no-array-index-key */
import React from 'react';
import QRCode from 'react-qr-code';

import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableRow,
  tableCellClasses,
} from '@mui/material';

import { OrderType } from 'commons/types';

import { BasePrintComponentProps, IInvoiceMappedData } from '../../interfaces';

import S from './TTFPrintTemplate.styles';

type TTFPrintTemplateComponentProps =
  BasePrintComponentProps<IInvoiceMappedData>;

export const TTFPrintTemplateComponent = ({
  data,
}: TTFPrintTemplateComponentProps) => {
  const renderSignatureSection = () => (
    <S.SignatureSection container>
      <Grid item xs={6} />
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12} textAlign="center">
            Yang Menerima,
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={6}>
            tanggal:
          </Grid>
          <Grid item xs={4}>
            jam:
          </Grid>
          <Grid item xs={12}>
            <S.Void />
            <S.UnderLine />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} />
    </S.SignatureSection>
  );

  const renderBody = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={6} style={{ fontSize: 14 }}>
            <b>SWIPE RX</b>
          </Grid>
          <Grid item xs={6} textAlign="right" style={{ fontSize: 14 }}>
            {data.customer_id} - {data.customer_name}
          </Grid>
        </Grid>
        {/* TUKAR FAKTUR SECTION */}
        <div style={{ textAlign: 'center' }}>
          <S.Title>TANDA TERIMA TUKAR FAKTUR</S.Title>
        </div>
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                fontFamily: 'Arial',
                fontSize: 12,
              },
            }}
            size="small"
            aria-label="a dense table"
          >
            <S.TableHeadStyle>
              <TableRow>
                <S.TableHeaderCell>No</S.TableHeaderCell>
                <S.TableHeaderCell>NO-FAKTUR</S.TableHeaderCell>
                <S.TableHeaderCell>TGL FAKTUR</S.TableHeaderCell>
                <S.TableHeaderCell align="right">JUMLAH</S.TableHeaderCell>
                <S.TableHeaderCell align="right">
                  TGL JT TEMPO
                </S.TableHeaderCell>
                <S.TableHeaderCell>KETERANGAN</S.TableHeaderCell>
              </TableRow>
            </S.TableHeadStyle>
            <S.TableBodyStyle>
              <TableRow
                key="TTFRow"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <S.TableBodyCell>1</S.TableBodyCell>
                <S.TableBodyCell>{data.invoice_number}</S.TableBodyCell>
                <S.TableBodyCell>{data.order_date}</S.TableBodyCell>
                <S.TableBodyCell align="right">
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(data.total_amount_with_ppn)}
                </S.TableBodyCell>
                <S.TableBodyCell align="right">{data.due_date}</S.TableBodyCell>
              </TableRow>
            </S.TableBodyStyle>
          </Table>
        </TableContainer>
        <S.UnderLine />
        {renderSignatureSection()}

        {/* Footer Section */}
        <S.FooterSection container>
          <Grid item xs={6}>
            Untuk info lebih lanjut hubungi CS di: (021) 50959952 <br />
            <QRCode
              size={64}
              value={`PT-DLV-${data.po_number}`}
              style={{ marginTop: 16 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                Note: <br />
                Penyerahan barang disertai dengan pengisian (ttd dan stempel)
                serta penyerahan kelengkapan dokumen. Dokumen yang dikembalikan
                :
              </Grid>
              <S.CheckboxContainer item xs={12}>
                <S.Checkbox checked />
                <S.CheckBoxText>1 Copy Faktur</S.CheckBoxText>
              </S.CheckboxContainer>
              <S.CheckboxContainer item xs={12}>
                <S.Checkbox checked />
                <S.CheckBoxText>1 Copy Faktur</S.CheckBoxText>
              </S.CheckboxContainer>
              <S.CheckboxContainer item xs={12}>
                <S.Checkbox
                  checked={
                    data.po_type !== OrderType.PRECURSOR &&
                    !data.is_digital_signed
                  }
                />
                <S.CheckBoxText>Surat Pesanan Reguler / Alkes</S.CheckBoxText>
              </S.CheckboxContainer>
              <Grid xs={6}>
                <p style={{ paddingLeft: 35 }}>Surat Pesanan Prekursor</p>
              </Grid>
              <S.CheckboxContainer item xs={3}>
                <S.Checkbox
                  checked={
                    data.po_type === OrderType.PRECURSOR &&
                    data.is_digital_signed
                  }
                />
                <S.CheckBoxText>e-SP</S.CheckBoxText>
              </S.CheckboxContainer>
              <S.CheckboxContainer item xs={3}>
                <S.Checkbox
                  checked={
                    data.po_type === OrderType.PRECURSOR &&
                    !data.is_digital_signed
                  }
                />
                <S.CheckBoxText>Non e-SP</S.CheckBoxText>
              </S.CheckboxContainer>
              <S.CheckboxContainer item xs={12}>
                <S.Checkbox checked />
                <S.CheckBoxText>BA Retur (jika ada retur)</S.CheckBoxText>
              </S.CheckboxContainer>
            </Grid>
          </Grid>
        </S.FooterSection>
      </>
    );
  };

  return (
    <S.PageBody>
      {renderBody()}
      <S.UnderLine className="dashed" />
      {renderBody()}
    </S.PageBody>
  );
};
