import React from 'react';

import { Typography } from '@mui/material';

import * as S from 'commons/components/Table/Table.styles';
import { DataTableProps } from 'commons/types/listPageData.interface';

export const BasketInfoDataTable: React.FC<
  React.PropsWithChildren<DataTableProps>
> = (params): React.ReactNode => {
  const { children, rowTitles = [] }: React.PropsWithChildren<DataTableProps> =
    params;

  return (
    <S.TableWrapper
      style={{
        overflow: 'scroll-x',
      }}
    >
      <S.TableHeader>
        <tr>
          {rowTitles.map((title) => {
            return (
              <S.TableHeaderRow
                key={title.title}
                style={{ textAlign: title.isCentered ? 'center' : 'left' }}
              >
                <Typography variant="body2">{title.title}</Typography>
              </S.TableHeaderRow>
            );
          })}
        </tr>
      </S.TableHeader>
      {children}
    </S.TableWrapper>
  );
};
