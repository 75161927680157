import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class AssignTaskPageStyle extends CommonComponentStyle {
  SubtitleWrapper = styled(Grid)`
    padding: 8px 0 16px 0;
  `;

  Subtitle = styled(Grid)`
    font-size: 14px;
    font-weight: 700;
  `;

  SelectItems = styled.button`
    border: none;
    color: #1733e8;
    font-size: 14px;
    background-color: transparent;
  `;

  GridWrapper = styled(Grid)`
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  PoNumberGridWrapper = styled(Grid)`
    background: rgba(250, 250, 250);
    padding: 8px;
  `;

  PoInfoGrid = styled(Grid)`
    font-size: 14px;
    font-weight: 700;
    margin-top: 8px !important;

    &.priority {
      text-align: right;
    }
  `;

  ButtonGrid = styled(Grid)`
    text-align: right;
  `;

  AssignBasketButton = styled.button`
    font-size: 14px;
    background-color: transparent;
    border: none;
    padding: 0;
  `;

  AssignBasketIcon = styled.img`
    width: 28px;
  `;

  BasketInfoGridWrapper = styled(Grid)`
    margin-bottom: 8px;
    padding-left: 8px;
  `;

  BasketInfoGrid = styled(Grid)`
    font-size: 14px;
    color: #737373;
    font-weight: 700;
    padding: 8px 0;

    span {
      font-weight: 400;
    }
  `;

  AddSOButtonWrapper = styled.div`
    text-align: center;
    width: 100%;
    margin-top: 8px;
  `;
}

export default new AssignTaskPageStyle();
