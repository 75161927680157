import styled from 'styled-components';

export const TableWrapper = styled.table`
  width: 100%;
`;

export const TableHeader = styled.thead`
  font-size: 12px;
  color: #737373;
  text-align: left;
`;

export const TableHeaderRow = styled.th`
  padding: 0;
`;

export const TableBody = styled.tbody<{ fontSize?: string }>`
  background: #fff;
  font-weight: 300;
  color: #000;
  font-size: ${(props): string => props.fontSize || '14px'};
`;

export const TableColumn = styled.td`
  padding: 15px 0;
`;
export const TableRow = styled.tr`
  margin-top: 5px;
  &:hover {
    background-color: #f2f2f2;
  }
`;
