import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

class SwipeRxWmsApiPackingUpload extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.PACKING_UPLOAD);
    this.basePath = `/${this.apiResource}`;
  }

  async uploadPhoto(poNumber: string, basket: number, formData: FormData) {
    try {
      const result = await this.post(
        `${this.basePath}/${poNumber}/${basket}/upload-basket-photo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async deleteBasketPhoto(poNumber: string, basket: number, photoLink: string) {
    try {
      const result = await this.delete(
        `${this.basePath}/${poNumber}/${basket}/delete-basket-photo`,
        {
          photo_link: photoLink,
        },
      );
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swipeRxWmsAPIPackingUpload = new SwipeRxWmsApiPackingUpload();
