import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  ArrowBackIos,
  AccountCircle,
  Logout as LogoutIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';

import { logout, selectUser } from 'redux-stores/features/authSlice';

import * as S from './Header.styles';

interface HeaderProps {
  title?: string;
  prevPageHandler?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ title, prevPageHandler }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const backToPreviousPage = (): void => {
    if (prevPageHandler) {
      prevPageHandler();
    } else {
      navigate(-1);
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    dispatch(logout());
    setAnchorEl(null);
  };

  return (
    <>
      <S.Wrapper>
        <Grid container style={{ maxHeight: 50 }}>
          <S.GridItem item xs={10}>
            {title ? (
              <>
                <IconButton onClick={backToPreviousPage} style={{ padding: 0 }}>
                  <ArrowBackIos style={{ color: '#000' }} />
                </IconButton>
                <S.HeaderTitle>{title}</S.HeaderTitle>
              </>
            ) : (
              <S.HeaderLogo src="/assets/swiperx-logo.svg" />
            )}
          </S.GridItem>
          <S.GridItem item xs={2}>
            <div>
              <IconButton sx={{ mr: 2 }} onClick={handleMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem>
                  <IconButton>
                    <AccountCircle />
                  </IconButton>
                  <S.MenuText>
                    {user?.userName} ({user?.role})
                  </S.MenuText>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <IconButton>
                    <LogoutIcon />
                  </IconButton>
                  <S.MenuText>Logout</S.MenuText>
                </MenuItem>
              </Menu>
            </div>
          </S.GridItem>
        </Grid>
      </S.Wrapper>
      {/* Add fixed space so every page will start below header */}
      <div style={{ marginTop: 55 }} />
    </>
  );
};
