import React from 'react';

import { SnackbarContent, SnackbarContentProps } from '@mui/material';
import styled from 'styled-components';

interface ContentProps extends SnackbarContentProps {
  color: string;
}

const getContentBackground = ({ color }: ContentProps): string => color;

const Content = styled(SnackbarContent)`
  && {
    background: ${getContentBackground as any};
  }
`;

export const S = {
  Content: Content as React.FC<ContentProps>,
};
