/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IInvoiceMappedData } from '../../interfaces';

import { TTFPrintTemplateComponent } from './TTFPrintTemplate.component';

const TTFPrintTemplate: React.FC = () => {
  document.body.className = 'print-template';
  const [searchParams] = useSearchParams();

  const data: IInvoiceMappedData = JSON.parse(searchParams.get('data') || '');

  return <TTFPrintTemplateComponent data={data} />;
};

export default TTFPrintTemplate;
