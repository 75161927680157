import React from 'react';
import { Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import OrderListPage from 'pages/dashboard/order-lists/OrderList';
import ReplenishListPage from 'pages/dashboard/replenish-list/ReplenishList';
import DashboardPage from 'pages/dashboard/summaries/Dashboard';
import UserListPage from 'pages/dashboard/user-list/UserList';

export const useDashboardRouter = (): React.JSX.Element => (
  <Route
    path="/dashboard"
    element={
      <PrivateRoute
        roles={[
          UserRolesEnum.ADMIN,
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.PICKER_LEAD,
        ]}
      />
    }
  >
    <Route path="/dashboard" element={<DashboardPage />} />
    <Route path="/dashboard/order-item" element={<OrderListPage />} />
    <Route path="/dashboard/users" element={<UserListPage />} />
    <Route path="/dashboard/replenish-item" element={<ReplenishListPage />} />
  </Route>
);
