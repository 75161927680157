import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class AssignBasketModalStyle extends CommonComponentStyle {
  TitleWrapper = styled.div`
    background-color: ${this.colors.LIGHTER_GRAY};
    padding: 8px;
  `;

  Title = styled.p`
    color: ${this.colors.PRIMARY_GRAY};
    font-weight: 700;
    font-size: 14px;
    margin: 8px;
  `;

  SubTitle = styled.p`
    font-size: 14px;
    font-weight: 700;
    margin: 4px 8px;
  `;

  FieldRowWrapper = styled(Grid)`
    margin: 12px 0;
    width: 75vw;
  `;

  FooterWrapper = styled.div`
    text-align: center;
    width: 100%;
    padding: 16px 0;
  `;
}

export default new AssignBasketModalStyle();
