import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import { ReplenishTaskStatusEnum } from 'commons/enums';
import { ReplenishTask } from 'commons/types';
import {
  prioritizeReplenishTask,
  retryUpdateFailedOrderReplenish,
} from 'redux-stores/actions';

export const RowMenuItemConfig = [
  {
    name: 'Prioritize',
    onClick: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      replenishNumber: string,
      location?: number,
    ): void => {
      dispatch(prioritizeReplenishTask({ replenishNumber, location }));
    },
    disabled: (selected: ReplenishTask[]): boolean => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].replenish_user === null) return false;
      }
      return true;
    },
  },
  {
    name: 'Retry',
    onClick: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      replenishNumber: string,
      location?: number,
    ): void => {
      dispatch(retryUpdateFailedOrderReplenish({ replenishNumber, location }));
    },
    disabled: (selected: ReplenishTask[]): boolean => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].status === ReplenishTaskStatusEnum.UPDATE_FAILED)
          return false;
      }
      return true;
    },
  },
];
