import React from 'react';
import { Route } from 'react-router-dom';

import CodeFlowCallback from 'pages/login/CodeFlowCallback';
import LoginPage from 'pages/login/Login';

export const useAuthRouter = (): React.JSX.Element => (
  <>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/code-flow-callback" element={<CodeFlowCallback />} />
  </>
);
