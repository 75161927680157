export const PRIMARY_BLUE = '#1733E8';
export const LIGHT_BLUE = '#CCE3FF';
export const LIGHTER_BLUE = 'rgba(23, 51, 232, 0.5)';

export const PRIMARY_GREEN = '#14BA7D';

export const PRIMARY_YELLOW = '#FBBF24';
export const LIGHT_YELLOW = '#FEF3C7';

export const PRIMARY_RED = '#EF4444';
export const LIGHT_RED = '#ef444447';

export const PRIMARY_ORANGE = '#FF7F30';
export const LIGHTER_ORANGE = 'rgba(255, 191, 151, 0.5)';

export const PRIMARY_GRAY = '#737373';
export const LIGHT_GRAY = '#D4D4D4';
export const LIGHTER_GRAY = 'rgba(0, 0, 0, 0.02)';

export const PRIMARY_WHITE = '#FFFFFF';
export const PRIMARY_BLACK = '#000000';

export const PROGRESS_ICON_COLOR = [
  PRIMARY_GRAY,
  PRIMARY_GREEN,
  PRIMARY_YELLOW,
];
