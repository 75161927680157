import { Grid } from '@mui/material';
import { styled } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: #fff;
  top: 0;
  left: 0;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

export const HeaderTitle = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const HeaderLogo = styled.img`
  width: 135px;
`;

export const GridItem = styled(Grid)`
  padding: 12px 8px 8px 8px;
  height: 50px;
`;

export const MenuIcon = styled.img`
  margin-top: 6px;
  height: 45px;
  size: 'small';
  color: 'inherit';
`;

export const MenuText = styled.p`
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #000;
  margin-left: 8px;
`;
