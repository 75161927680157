import React from 'react';

import { capitalize } from '@mui/material';

import { ProductDetail } from 'commons/types';
import { TableConfig } from 'commons/types/tableConfig.interface';

const formatText = (text: string, alignCenter = false): React.JSX.Element => {
  return (
    <div style={{ textAlign: alignCenter ? 'center' : 'left' }}>{text}</div>
  );
};

export const sourceConfig: TableConfig = [
  {
    header: 'Bin',
    element: (bin: ProductDetail): React.ReactElement => {
      return formatText(bin.bin || '-');
    },
  },
  {
    header: 'Type BIN',
    element: (bin: ProductDetail): React.ReactElement => {
      return formatText(capitalize((bin.bin_type ?? '').replace('_', ' ')));
    },
  },
  {
    headerElement: formatText('QTY Bin', true),
    element: (bin: ProductDetail): React.ReactElement => {
      return formatText(bin.quantity.toString(), true);
    },
  },
];
