import { KeyboardArrowDown } from '@mui/icons-material';
import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { PRIMARY_GRAY } from 'commons/styles/colors';

class PickItemPageStyle extends CommonComponentStyle {
  HeaderContentWrapper = styled(Grid)`
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  HeaderTableWrapper = styled(Grid)`
    padding: 8px 16px 00px 16px;
    font-size: 14px;
  `;

  HeaderReplenishContentWrapper = styled.div`
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  HeaderReplenishGridContainer = styled(Grid)`
    padding: 10px 8px 8px 8px;
    background-color: ${this.colors.LIGHT_RED};
  `;

  HeaderReplenishGrid = styled(Grid)`
    font-size: 14px;
    font-weight: 700;
  `;

  BlueButtonTextNoBG = styled(this.BlueButtonText)`
    background-color: rgb(0, 0, 0, 0) !important;
  `;

  SubtitleWrapper = styled(Grid)`
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  Subtitle = styled(Grid)`
    font-weight: 700;
    font-size: 14px;
  `;

  UOMSubtitle = styled(Grid)`
    color: ${PRIMARY_GRAY};
    font-size: 12px;
    font-weight: 700;
  `;

  ProgressText = styled.p`
    font-weight: 700;
    font-size: 14px;
    color: ${this.colors.PRIMARY_GRAY};
  `;

  ProgressSpan = styled.span`
    color: ${(props) => props.color || this.colors.PRIMARY_GRAY};
    margin-left: 8px;
  `;

  PickProcessWrapper = styled.div`
    font-size: 14px;
  `;

  PickProcessGridContainer = styled(Grid)`
    margin-top: 16px;
  `;

  PickProcessArrow = styled(KeyboardArrowDown)`
    font-size: 14px !important;
    color: ${this.colors.PRIMARY_GRAY};
    &.open {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
    &.close {
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
  `;

  PickProcessTitleText = styled(Grid)`
    font-weight: 700;
    p {
      margin: 0;
    }
  `;

  AddBasketButtonWrapper = styled.div`
    text-align: center;
    margin-top: 16px;
  `;

  PickProcessInputGrid = styled(Grid)`
    margin-top: 8px !important;
  `;
}

export default new PickItemPageStyle();
