import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos } from '@mui/icons-material';
import { Grid } from '@mui/material';

import UserInfoHeader from './components/UserInfoHeader.component';
import S from './HomePage.styles';

const HomePageInbound: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToHomePage = (): void => {
    navigate('/');
  };
  return (
    <div>
      <UserInfoHeader title="Inbound" prevPageHandler={handleBackToHomePage} />

      {/* Menu Options */}
      {/* Replenishment */}
      <S.MenuOption onClick={() => navigate('/replenish/assign')}>
        <Grid container>
          <Grid item xs={2}>
            <S.MenuIcon
              src="/assets/replenishment-icon.svg"
              alt="replenishment-icon"
            />
          </Grid>
          <Grid item xs={8}>
            <S.MenuText>Replenishment</S.MenuText>
          </Grid>
          <Grid item xs={2}>
            <ArrowForwardIos
              fontSize="small"
              style={{ marginTop: 14, textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </S.MenuOption>
    </div>
  );
};

export default HomePageInbound;
