import React, { useState } from 'react';

import { Checkbox } from '@mui/material';

import * as S from 'commons/components/Table/Table.styles';
import { RowTitleProps } from 'commons/types/listPageData.interface';
import { User } from 'commons/types/user.interface';
import { UserTableProps } from 'commons/types/userData.interface';
import { TableActionRow, DataTable } from 'pages/dashboard/commons/components';

const rowTitles: RowTitleProps[] = [
  { title: 'Username' },
  { title: 'Role' },
  { title: 'Warehouse' },
  { title: 'Status' },
];

const Row: React.FC<{
  data: User;
  onCheckboxClick: (userName: string) => void;
  checked: boolean;
}> = ({ data, onCheckboxClick, checked }) => {
  return (
    <S.TableRow>
      <S.TableColumn>
        <Checkbox
          onClick={(): void => onCheckboxClick(data.userName)}
          checked={checked}
        />
      </S.TableColumn>
      <S.TableColumn />
      <S.TableColumn>{data.userName}</S.TableColumn>
      <S.TableColumn style={{ textTransform: 'capitalize' }}>
        {data.role}
      </S.TableColumn>
      <S.TableColumn>{data.warehouse}</S.TableColumn>
      <S.TableColumn style={{ textTransform: 'capitalize' }}>
        {data.userStatus}
      </S.TableColumn>
    </S.TableRow>
  );
};

const UserTable: React.FC<UserTableProps> = (params): React.ReactNode => {
  const { data = [] }: UserTableProps = params;

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkedUsers, setCheckedUsers] = useState<string[]>([]);

  const selectedUsers = data.filter((user) =>
    checkedUsers.includes(user.userName),
  );

  const handleCheckUser = (userName: string): void => {
    if (checkedUsers.includes(userName)) {
      checkedUsers.splice(checkedUsers.indexOf(userName), 1);
      setCheckedUsers([...checkedUsers]);
    } else {
      setCheckedUsers([...checkedUsers, userName]);
    }
  };

  const handleCheckAll = (): void => {
    if (checkAll) {
      setCheckedUsers([]);
    } else {
      setCheckedUsers(data.map((user) => user.userName));
    }
    setCheckAll(!checkAll);
  };

  return (
    <>
      <TableActionRow dataName="user" selectedData={selectedUsers} />
      <DataTable
        handleCheckAll={handleCheckAll}
        checkAll={checkAll}
        rowTitles={rowTitles}
      >
        <S.TableBody>
          {data.map((item) => (
            <Row
              data={item}
              onCheckboxClick={handleCheckUser}
              checked={checkedUsers.includes(item.userName)}
            />
          ))}
        </S.TableBody>
      </DataTable>
    </>
  );
};

export default UserTable;
