import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDebouncedCallback } from 'use-debounce';

import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { UserStatusEnum } from 'commons/enums';
import { getUserListAction } from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';

import { ListFilterCard, ListPage } from '../commons/components';

import UserTable from './components/UserTable.component';

const UserListPage: React.FC = () => {
  const { updateQueryParams } = useUpdateQueryParams();

  const { userList } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const updateUserListSearchDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getUserListAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  useEffect(() => {
    updateQueryParams({ status: UserStatusEnum.ACTIVE });
  }, []);

  const activityStatusArrayText: {
    key: UserStatusEnum;
    text: string;
  }[] = [
    ...Object.values(UserStatusEnum).map((value) => ({
      key: value,
      text: value,
    })),
  ];

  const [statusFilter, setStatusFilter] = useState<UserStatusEnum | null>(
    UserStatusEnum.ACTIVE,
  );

  const handleStatusFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: UserStatusEnum | null,
  ): void => {
    setStatusFilter(value);
    updateQueryParams({ status: value });
  };

  return (
    <ListPage updateListSearchDebounce={updateUserListSearchDebounce}>
      <ListFilterCard
        statusArrayText={activityStatusArrayText}
        statusFilter={statusFilter}
        handleStatusFilter={handleStatusFilter}
      />
      <UserTable data={userList} />
    </ListPage>
  );
};

export default UserListPage;
