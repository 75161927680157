import React from 'react';

import { Grid } from '@mui/material';

import { TableComponent } from 'commons/components/Table';
import { ReplenishBinWithMissingQty, ReplenishItemProps } from 'commons/types';

import { ReplenishSummaryStyle as S } from '../ReplenishSummary.style';

import {
  partialTableConfig,
  remainsTableConfig,
  summaryTableConfig,
} from './summary-table.config';

export interface IReplenishBinListProps {
  sources: ReplenishItemProps[];
  destination: ReplenishItemProps[];
  totalBinQtyMissing: ReplenishBinWithMissingQty[];
  remainsQty?: { inventory_number: string; quantity: number }[];
}

export const ReplenishBinList: React.FC<IReplenishBinListProps> = ({
  sources,
  destination,
  totalBinQtyMissing,
  remainsQty,
}) => {
  return (
    <>
      <S.ItemContentWrapper container>
        <Grid item xs={12}>
          <S.SubHeaderText>Source</S.SubHeaderText>
        </Grid>
        <TableComponent
          data={sources || []}
          config={summaryTableConfig}
          additionalTableConfig={{ bodyFontSize: '12px' }}
        />
      </S.ItemContentWrapper>

      <S.ItemContentWrapper container>
        <Grid item xs={12}>
          <S.SubHeaderText>Destination</S.SubHeaderText>
        </Grid>
        <TableComponent
          data={destination || []}
          config={summaryTableConfig}
          additionalTableConfig={{ bodyFontSize: '12px' }}
        />
      </S.ItemContentWrapper>

      {remainsQty && remainsQty.length > 0 && (
        <S.ItemContentWrapper container>
          <Grid item xs={12}>
            <S.SubHeaderText>Unplaced Items</S.SubHeaderText>
          </Grid>
          <TableComponent
            data={remainsQty || []}
            config={remainsTableConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.ItemContentWrapper>
      )}
      {totalBinQtyMissing.length > 0 && (
        <S.ItemContentWrapper container>
          <Grid item xs={12}>
            <S.SubHeaderText>Bin Partially Replenished</S.SubHeaderText>
          </Grid>
          <TableComponent
            data={totalBinQtyMissing || []}
            config={partialTableConfig}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.ItemContentWrapper>
      )}
    </>
  );
};
