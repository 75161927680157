import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Divider } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import {
  CriteriaOption,
  SearchWithCriteria,
} from 'commons/components/SearchRelated';
import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { ReplenishTaskStatusEnum } from 'commons/enums';
import { getReplenishListAction } from 'redux-stores/actions/dashboard.action';
import { RootReducerInterface } from 'redux-stores/reducers';

import { ListPage, ListFilterCard } from '../commons/components';

import ReplenishTable from './components/ReplenishTable.component';

const ReplenishListPage: React.FC = () => {
  const { updateQueryParams } = useUpdateQueryParams();

  const { replenishList } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const updateReplenishListSearchDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getReplenishListAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  const searchCriteria: CriteriaOption[] = [
    {
      name: 'SKU',
      id: 'sku_code',
      isArray: true,
      parameterMapping: 'sku_codes',
    },
    {
      name: 'Replenisher',
      id: 'replenish_user',
    },
    {
      name: 'Replenish Type',
      id: 'type',
    },
  ];

  const replenishTaskStatusArrayText: {
    key: ReplenishTaskStatusEnum;
    text: string;
  }[] = [
    ...Object.values(ReplenishTaskStatusEnum).map((value) => ({
      key: value,
      text: value.replace('-', ' '),
    })),
  ];

  const [statusFilter, setStatusFilter] =
    useState<ReplenishTaskStatusEnum | null>(null);

  const handleStatusFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ReplenishTaskStatusEnum,
  ): void => {
    setStatusFilter(value);
    updateQueryParams({ status: value }, { clearQueryParams: ['page'] });
  };

  return (
    <ListPage updateListSearchDebounce={updateReplenishListSearchDebounce}>
      <ListFilterCard
        statusArrayText={replenishTaskStatusArrayText}
        statusFilter={statusFilter}
        handleStatusFilter={handleStatusFilter}
      >
        <SearchWithCriteria searchCriteriaOptions={searchCriteria} />
        <Divider variant="fullWidth" />
      </ListFilterCard>
      <ReplenishTable data={replenishList} statusFilter={statusFilter} />
    </ListPage>
  );
};

export default ReplenishListPage;
