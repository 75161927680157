import { Grid, TableBody, TableCell, TableHead } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { LIGHT_GRAY } from 'commons/styles/colors';

class TTFPrintTemplateStyle extends CommonComponentStyle {
  PageBody = styled.div`
    padding: 8mm;
    width: 210mm;
    height: 297mm;

    @page {
      size: portrait;
    }
  `;

  Title = styled.h5`
    margin: 16px 8px;
  `;

  TableHeadStyle = styled(TableHead)`
    background-color: #d3d3d3 !important;
  `;

  TableHeaderCell = styled(TableCell)`
    font-weight: 700 !important;
    padding: 0 8px !important;
  `;

  TableBodyStyle = styled(TableBody)`
    border: none;
  `;

  TableBodyCell = styled(TableCell)`
    line-height: 100% !important;
    padding: 4px 8px !important;
    vertical-align: top !important;
  `;

  UnderLine = styled.hr`
    width: 100%;
    border: 1px solid ${LIGHT_GRAY};
    margin-top: 16px;

    &.dashed {
      border: 1px dashed ${LIGHT_GRAY};
      margin: 36px 0;
    }
  `;

  Void = styled.div`
    height: 10mm;

    &.bigger {
      height: 10mm;
    }
  `;

  SignatureSection = styled(Grid)`
    font-size: 12px;
    margin-top: 16px;
  `;

  FooterSection = styled(Grid)`
    font-size: 12px;
    margin: 8px 0;
  `;

  CheckBoxText = styled.span`
    margin-left: 8px;
    margin-top: 5px;
  `;

  Checkbox = styled.div<{ checked: boolean }>`
    height: 20px;
    width: 20px;
    background-color: #e6e6e6;
    border: 1px solid black;

    ${(props) =>
      props.checked &&
      `
        &:after {
          content: '✔';
          font-size: 16px;
          margin-left: 4px;
          color: #000;
        }`}
  `;

  CheckboxContainer = styled(Grid)`
    display: inline-flex;
    margin-top: 16px !important;
  `;
}

export default new TTFPrintTemplateStyle();
