import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import { PickingTask } from 'commons/types';
import {
  prioritizeOrderPick,
  retryUpdateFailedOrder,
} from 'redux-stores/actions';

export const RowMenuItemConfig = [
  {
    name: 'Prioritize',
    onClick: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      poNumber: string,
      location?: number,
    ): void => {
      dispatch(prioritizeOrderPick({ poNumber, location }));
    },
    disabled: (selected: PickingTask[]): boolean => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].picker === null) return false;
      }
      return true;
    },
  },
  {
    name: 'Retry',
    onClick: (
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
      poNumber: string,
      location?: number,
    ): void => {
      dispatch(retryUpdateFailedOrder({ poNumber, location }));
    },
    disabled: (selected: PickingTask[]): boolean => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].status === 'update-failed') return false;
      }
      return true;
    },
  },
];
