import React from 'react';

import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { darken } from 'polished';
import styled from 'styled-components';

import * as colors from 'commons/styles/colors';

type WrapperProps = Omit<ButtonProps, 'color'> & {
  color: string;
  isSecondary?: boolean;
};

const getWrapperBackground = ({
  color,
  disabled,
  isSecondary,
}: WrapperProps): string =>
  disabled ? colors.LIGHT_GRAY : isSecondary ? colors.LIGHTER_GRAY : color;

const getWrapperHoverBackground = ({
  color,
  isSecondary,
}: WrapperProps): string =>
  darken(0.05, isSecondary ? colors.LIGHT_GRAY : color);

const getWrapperColor = ({
  color,
  disabled,
  isSecondary,
}: WrapperProps): string =>
  disabled ? colors.PRIMARY_GRAY : isSecondary ? color : colors.PRIMARY_WHITE;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Wrapper = styled(({ color = '', isSecondary = false, ...props }) => (
  <Button data-testid="action-btn" {...props} />
))`
  && {
    background: ${(props): string =>
      getWrapperBackground({
        color: props.color || colors.LIGHT_GRAY,
        ...props,
      })};
    color: ${(props): string =>
      getWrapperColor({
        color: props.color || colors.LIGHT_GRAY,
        ...props,
      })};
    font-weight: bold;
    text-transform: none;
    padding: 5px 24px;

    &:hover {
      background: ${(props): string =>
        getWrapperHoverBackground({
          color: props.color || colors.LIGHT_GRAY,
          ...props,
        })};
    }
  }
`;

const Loader = styled(CircularProgress)`
  && {
    position: absolute;
  }
`;

const RemoveButton = styled.button`
  && {
    display: flex;
    align-items: center;
    margin-left: 6px;
    cursor: pointer;
    background: ${colors.PRIMARY_WHITE};
    border: 0;
    padding: 0;

    &:focus {
      outline: none;
    }
  }
`;

export const S = {
  Wrapper: Wrapper as React.FC<WrapperProps>,
  RemoveButton,
  Loader,
};
