/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import JsBarcode from 'jsbarcode';

import { OrderType } from 'commons/types';

import {
  BasePrintComponentProps,
  IInvoiceMappedData,
  IRenderedPickingItem,
} from '../../interfaces';

import S from './InvoicePrintTemplate.styles';

interface InvoicePrintTemplateComponentProps
  extends BasePrintComponentProps<IInvoiceMappedData> {
  setMaxPageNumber?: (page: number) => void;
}

const BarcodeImage = ({ value }: { value: string }) => {
  const barcodeRef = React.useRef(null);

  useEffect(() => {
    JsBarcode(barcodeRef.current, value, {
      format: 'CODE128',
      width: 1.5,
      height: 50,
      displayValue: true,
      fontSize: 10,
    });
  }, [value]);

  return <img ref={barcodeRef} alt="Barcode" />;
};

const getMaxPageNumber = (tableData: IRenderedPickingItem[][]): number => {
  const additionalPage = tableData[tableData.length - 1].length > 10 ? 1 : 0;

  return tableData.length + additionalPage;
};

export const InvoicePrintTemplateComponent = ({
  data,
  setMaxPageNumber,
}: InvoicePrintTemplateComponentProps): React.JSX.Element => {
  const [maxPage, setMaxPage] = useState<number>(0);
  const renderHeader = () => (
    <S.PageHeader>
      <Grid container>
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <S.DigitalSignatureLabel>
            {data.is_digital_signed ? 'SP Electronic' : 'SP Manual'}
          </S.DigitalSignatureLabel>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <S.FakturLabel>FAKTUR</S.FakturLabel>
        </Grid>
      </Grid>
      {/* Invoice Cope Section */}
      <S.InvoiceCopeWrapper container>
        <Grid item xs={1}>
          <img
            src="/assets/print-template/TMPLogo.png"
            alt="logo"
            style={{
              width: '250%',
              marginTop: '24px',
              marginLeft: '-40px',
            }}
          />
        </Grid>
        {/* Middle Section Company Profile */}
        <S.CompanyProfileWrapper item xs={6}>
          <div>
            <b>PT. Teknologi Medika Pratama</b>
          </div>
          <S.CompanyAddressText>
            {data.company_info?.address}
          </S.CompanyAddressText>
          <div>No Izin PBF: {data.company_info?.pbf_no}</div>
          <div>No Izin DAK: {data.company_info?.dak_no}</div>
          <div>No Sertifikat CDOB : {data.company_info?.cdob_no}</div>
        </S.CompanyProfileWrapper>
        {/* Right Section Nomor Faktur etc */}
        <Grid item xs={5} style={{ paddingTop: 4 }}>
          <Grid container>
            <Grid item xs={4}>
              <b>Nomor Faktur</b>
            </Grid>
            <Grid item xs={1}>
              :
            </Grid>
            <Grid item xs={7}>
              {data.invoice_number}
            </Grid>
            {data.po_type !== OrderType.REGULAR && (
              <Grid item xs={12}>
                <S.OrderTypeText>
                  {data.po_type === OrderType.PRECURSOR ? 'Prekursor' : 'Alkes'}
                </S.OrderTypeText>
              </Grid>
            )}
            <Grid item xs={4}>
              Tanggal
            </Grid>
            <Grid item xs={1}>
              :
            </Grid>
            <Grid item xs={7}>
              {data.order_date}
            </Grid>
            <Grid item xs={4}>
              Nomor PO
            </Grid>
            <Grid item xs={1}>
              :
            </Grid>
            <Grid item xs={7}>
              {data.po_number}
            </Grid>
            <Grid item xs={4}>
              Jatuh Tempo
            </Grid>
            <Grid item xs={1}>
              :
            </Grid>
            <Grid item xs={7}>
              {data.due_date}
            </Grid>
          </Grid>
        </Grid>
      </S.InvoiceCopeWrapper>

      {/* Customer Info Section */}
      <S.CustomerInfoWrapper container>
        <S.CustomerInfoContent item xs={2}>
          <b>Customer</b>
          <span style={{ marginLeft: 8 }}>:</span>
        </S.CustomerInfoContent>
        <S.CustomerInfoContent item xs={10}>
          {data.customer_name}
        </S.CustomerInfoContent>
        <S.CustomerInfoContent item xs={2}>
          <b>Alamat</b>
          <span style={{ marginLeft: 21 }}>:</span>
        </S.CustomerInfoContent>
        <S.CustomerInfoContent item xs={10}>
          {data.customer_address}
        </S.CustomerInfoContent>
      </S.CustomerInfoWrapper>
    </S.PageHeader>
  );

  // determine max page data
  useEffect(() => {
    const maxPageNumber = getMaxPageNumber(data.items);
    setMaxPage(maxPageNumber);

    if (setMaxPageNumber) setMaxPageNumber(maxPageNumber);
  }, [maxPage, data.items]);

  // Footer Section
  const renderFooter = (pageNumber: number, withRelativeTag: boolean = true) =>
    withRelativeTag ? (
      <div style={{ position: 'relative' }}>
        <S.PageFooter>
          <Grid container>
            <Grid item xs={3}>
              <QRCode size={72} value={`PT-INV-${data.po_number}`} />
            </Grid>
            <Grid item xs={7} className="print-barcode">
              <BarcodeImage value={data.invoice_number || '-'} />
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <S.PageNumberText>
                Page {pageNumber} of {maxPage}
              </S.PageNumberText>
            </Grid>
          </Grid>
        </S.PageFooter>
      </div>
    ) : (
      <S.PageFooter className="last-page" pageOffset={pageNumber - 1}>
        <Grid container>
          <Grid item xs={3}>
            <QRCode size={72} value={`PT-INV-${data.po_number}`} />
          </Grid>
          <Grid item xs={7} className="print-barcode">
            <BarcodeImage value={data.invoice_number || '-'} />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <S.PageNumberText>
              Page {pageNumber} of {maxPage}
            </S.PageNumberText>
          </Grid>
        </Grid>
      </S.PageFooter>
    );

  const renderBody = (itemData: IRenderedPickingItem[], dataIdx: number) => (
    <S.PageBody>
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <Table
          sx={{
            minWidth: 650,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
              fontFamily: 'Arial',
              fontSize: 12,
            },
          }}
          size="small"
          aria-label="a dense table"
        >
          <S.TableHeadStyle>
            <TableRow>
              <S.TableHeaderCell width={75}>SKU</S.TableHeaderCell>
              <S.TableHeaderCell width={200}>NAMA BARANG</S.TableHeaderCell>
              <S.TableHeaderCell width={25}>UNIT</S.TableHeaderCell>
              <S.TableHeaderCell width={50}>KEMASAN</S.TableHeaderCell>
              <S.TableHeaderCell width={210}>NO. BATCH</S.TableHeaderCell>
              <S.TableHeaderCell width={55}>ED</S.TableHeaderCell>
              <S.TableHeaderCell width={75} align="right">
                RP/UNIT
              </S.TableHeaderCell>
              <S.TableHeaderCell width={35} align="right">
                DISK
              </S.TableHeaderCell>
              <S.TableHeaderCell width={100} align="right">
                TOTAL
              </S.TableHeaderCell>
            </TableRow>
          </S.TableHeadStyle>
          <S.TableBodyStyle>
            {itemData.map((productInfo, idx) => (
              <TableRow
                key={`InvRow-${productInfo.sku_code}-${idx}`}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <S.TableBodyCell>{productInfo.sku_code}</S.TableBodyCell>
                <S.TableBodyCell>{productInfo.name}</S.TableBodyCell>
                <S.TableBodyCell align="center">
                  {productInfo.quantity}
                </S.TableBodyCell>
                <S.TableBodyCell>{productInfo.uom}</S.TableBodyCell>
                <S.TableBodyCell>{productInfo.inventory}</S.TableBodyCell>
                <S.TableBodyCell>{productInfo.expiry_date}</S.TableBodyCell>
                <S.TableBodyCell align="right">
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  })
                    .format(productInfo.price)
                    .replace('Rp', '')}
                </S.TableBodyCell>
                <S.TableBodyCell align="right">
                  {productInfo.discount_rate}
                </S.TableBodyCell>
                <S.TableBodyCell align="right">
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  })
                    .format(productInfo.net_price)
                    .replace('Rp', '')}
                </S.TableBodyCell>
              </TableRow>
            ))}
          </S.TableBodyStyle>
        </Table>
      </TableContainer>
      {dataIdx !== data.items.length - 1 ? (
        <>
          <div className="pagebreak-after" />
          <div style={{ width: '100%' }} />
          {renderFooter(dataIdx + 1)}
        </>
      ) : (
        <>
          {data.items[dataIdx].length > 10 && (
            <>
              <div className="pagebreak-after" />
              <div style={{ width: '100%' }} />
              {renderFooter(dataIdx + 1)}
              {/* Header Section */}
              {renderHeader()}
            </>
          )}
          {/* Summary Section */}
          <S.SummarySectionWrapper container>
            <Grid item xs={7}>
              Pembayaran Faktur ini dilakukan ke rekening Virtual Account di
              aplikasi SwipeRx
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={4}>
                  <b>Total Harga</b>
                </Grid>
                <Grid item xs={1}>
                  <b>:</b>
                </Grid>
                <Grid item xs={7} textAlign="right">
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(data.total_amount)}
                </Grid>
                <Grid item xs={4}>
                  <b>PPN</b>
                </Grid>
                <Grid item xs={1}>
                  <b>:</b>
                </Grid>
                <Grid item xs={7} textAlign="right">
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(data.ppn)}
                </Grid>
                <Grid item xs={4}>
                  <b>Total Tagihan</b>
                </Grid>
                <Grid item xs={1}>
                  <b>:</b>
                </Grid>
                <Grid item xs={7} textAlign="right">
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(data.total_amount_with_ppn)}
                </Grid>
              </Grid>
            </Grid>
            {/* Signature Section */}
            <S.SignatureSectionWrapper item xs={5}>
              <Grid container>
                <Grid item xs={4} style={{ display: 'flex' }}>
                  <p style={{ alignSelf: 'flex-end' }}>Diperiksa:</p>
                </Grid>
                <Grid item xs={4}>
                  <img
                    style={{ width: 120 }}
                    src={data.company_info?.apj_signature}
                    alt="ttd"
                  />
                </Grid>
                <Grid item xs={12}>
                  APJ : {data.company_info?.apj_name}
                </Grid>
                <Grid item xs={12}>
                  SIPA : {data.company_info?.apj_sipa}
                </Grid>
              </Grid>
            </S.SignatureSectionWrapper>
            <S.HandOverSectionWrapper item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  Barang ini diterima dalam kondisi baik
                </Grid>
                <Grid item xs={12}>
                  Diterima:
                </Grid>
                <Grid item xs={12}>
                  APJ/Aping/TTK Penerima:
                </Grid>
                <Grid item xs={12}>
                  SIPA/SIPTTK:
                </Grid>
              </Grid>
            </S.HandOverSectionWrapper>
            <S.HandOverSectionWrapper item xs={3}>
              <Grid container>
                <Grid item xs={12}>
                  Tanggal terima:
                </Grid>
                <Grid item xs={12}>
                  Jam terima:
                </Grid>
              </Grid>
            </S.HandOverSectionWrapper>
          </S.SummarySectionWrapper>
        </>
      )}
    </S.PageBody>
  );

  return (
    <div>
      <S.PageWrapper>
        {data.items.map((itemData, dataIdx) => (
          <>
            {/* Header Section */}
            {renderHeader()}

            {/* Body Section */}
            {renderBody(itemData, dataIdx)}

            {/* Footer Section */}
            {data.items.length === dataIdx + 1 &&
              renderFooter(
                dataIdx + (data.items[dataIdx].length > 10 ? 2 : 1),
                false,
              )}
          </>
        ))}
      </S.PageWrapper>
    </div>
  );
};
