/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { ClickAwayListener, MenuList, MenuItem } from '@mui/material';

import { ReplenishTask } from 'commons/types';

import { RowMenuItemConfig } from '../configs/row-menu-item.config';

const ActionBtnMenuReplenish: React.FC<{
  selectedTask: ReplenishTask[];
  handleCloseMenu: () => void;
  handleOpen: (name: string) => void;
}> = (params) => {
  const {
    selectedTask,
    handleCloseMenu,
    handleOpen,
  }: {
    selectedTask: ReplenishTask[];
    handleCloseMenu: () => void;
    handleOpen: (name: string) => void;
  } = params;

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <MenuList id="split-button-menu" autoFocusItem>
        {RowMenuItemConfig.map((config) => (
          <MenuItem
            onClick={() => handleOpen(config.name)}
            disabled={config.disabled(selectedTask)}
            key={`menu-item-${config.name}`}
          >
            {config.name}
          </MenuItem>
        ))}
      </MenuList>
    </ClickAwayListener>
  );
};

export default ActionBtnMenuReplenish;
