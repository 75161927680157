import { Box, Card } from '@mui/material';
import styled from 'styled-components';

class DashboardStyle {
  FlexboxWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  `;

  DashboardWrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    min-height: 100%;
    width: 100%;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  `;

  DashboardContentWrapper = styled(Box)`
    padding: 0.5rem;
    width: 100%;
  `;

  // TODO: make responsive
  DashboardContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0 auto;
  `;

  DashboardCard = styled(Card)`
    width: 100%;
    padding: 0.8rem 1rem;
  `;

  DashboardCardIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 100%;
    background-color: ${({ color }): string => `${color}20`};
    color: ${({ color }): string => `${color}`};
  `;

  DashboardCardContentHeader = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: left;
    align-items: center;
  `;

  CountInfoGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  `;
}

export const BaseDashboardStyle = new DashboardStyle();
