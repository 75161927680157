import { OrderStatusEnum } from 'commons/enums';

import { IInvoice } from './invoice.interface';
import { IPackingTask } from './packingTask.interface';
import { PharmacyDetails } from './pharmacyDetail.interface';
import { PickingListBySKUs } from './pickingList.interface';
import { ProductDetail } from './product.interface';

export interface IPickItemActual extends ProductDetail {
  basket: number;
}
export interface PickingItem {
  id: string;
  sku_code: string;
  uom?: string;
  name: string;
  quantity: number;
  pick_recommendation: ProductDetail | null;
  pick_actual: IPickItemActual[];
  price: number;
  discount: number;
  basket: number;
}

export enum OrderType {
  REGULAR = 'regular',
  ALKES = 'alkes',
  PRECURSOR = 'precursor',
}

export enum PriorityList {
  P0 = 'P0',
  P1 = 'P1',
  P1A = 'P1A',
  P1B = 'P1B',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
  P7 = 'P7',
  P8 = 'P8',
  P9 = 'P9',
}

export interface PickingTaskRelatedDocument {
  netsuite?: {
    item_fulfillment: number;
    createdAt?: Date;
  };
}

export interface PickingTask {
  id: string;
  po_number: string;
  status: OrderStatusEnum;
  items: PickingItem[];
  baskets: number[];
  warehouse_id: number;
  pharmacy_id: number;
  pharmacy: PharmacyDetails;
  po_id: number;
  po_type: OrderType;
  priority: PriorityList;
  cutoff_time?: string[]; // [HH:mm] | [HH:mm:ss]
  expected_dispatch_time?: string; // HH:mm
  dispatch_type?: string;
  picker: string | null;
  total_qty: number;
  total_sku: number;
  pick_type: string;
  queued_at: Date;
  assigned_at: Date;
  completed_at: Date | null;
  reason: string;
  is_high_priority: boolean;
  is_digital_signed?: boolean;
  tax_rate: number;
  related_document?: PickingTaskRelatedDocument;
  createdAt: Date;
  logistic_partner_id?: number | null;
  logistic_partner_name?: string | null;
  awb_number?: string | null;
  packing_task?: IPackingTask | null;
  invoice?: IInvoice | null;
}

export interface PickingTaskState {
  tasks: PickingTask[];
  assignedTasks: PickingTask[];
  tasksBySKUs: PickingListBySKUs[];
  skuList: string[];
  skuLowStockTriggered: { [K: string]: boolean };
}

// only po_number and baskets that are mandatory
export type PartialPickingTask = Partial<PickingTask> & {
  po_number: string;
  baskets: number[];
};
