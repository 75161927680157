import React from 'react';

import { Dialog } from '@mui/material';

import { IBasePartialReplenishModalProps } from './partial-replenish-modal.interface';
import * as S from './partial-replenish-modal.styles';

export const PartialReplenishModal: React.FC<
  IBasePartialReplenishModalProps
> = ({ partialReplenishedSKU, onClose, openModal, onConfirm }) => {
  // immediate return if empty
  if (partialReplenishedSKU.length === 0) {
    onClose();
    return <div />;
  }

  return (
    <Dialog onClose={onClose} open={openModal}>
      <S.TitleWrapper>
        <S.Title>SOME REQUESTED SKUs ARE STILL PARTIALLY FULFILLED:</S.Title>
      </S.TitleWrapper>

      <S.Text>Are you sure to complete replenish task?</S.Text>
      <S.Text>SKUs below are still partially replenished:</S.Text>
      {partialReplenishedSKU.map((sku) => {
        return (
          <S.Text>
            <b>{sku.sku_code}</b>: requested <b>{sku.totalQty.requested}</b>,
            replenished <b>{sku.totalQty.destination}</b>
          </S.Text>
        );
      })}

      {/* Footer */}
      <S.FooterWrapper>
        <S.CancelButton onClick={onClose}>NO</S.CancelButton>
        <S.OKButton onClick={() => onConfirm()}>YES</S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};
