import React from 'react';
import { Route } from 'react-router-dom';

import InvoicePrintTemplate from 'pages/pack/pack-print-page/print-components/invoice-template/InvoicePrintTemplate';
import InvoiceTTFPrintTemplate from 'pages/pack/pack-print-page/print-components/invoice-ttf-template/InvoiceTTFPrintTemplate';
import ShippingLabelPrintTemplate from 'pages/pack/pack-print-page/print-components/ship-label-template/ShippingLabelPrintTemplate';
import TTFPrintTemplate from 'pages/pack/pack-print-page/print-components/ttf-template/TTFPrintTemplate';

export const usePrintTemplateRouter = (): React.JSX.Element => (
  <Route path="/print">
    <Route path="/print/invoice" element={<InvoicePrintTemplate />} />
    <Route path="/print/invoice-ttf" element={<InvoiceTTFPrintTemplate />} />
    <Route path="/print/ttf" element={<TTFPrintTemplate />} />
    <Route path="/print/ship-label" element={<ShippingLabelPrintTemplate />} />
  </Route>
);
