import { ReplenishItemDestination, ReplenishItemProps } from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import {
  IGetProductDetailRequest,
  IGetReplenishJobBySkuCodeRequest,
  IGetReplinshJobBySkuCodeResponse,
  IPostPickSourceBinRequest,
  IPostPutDestinationRequest,
  IPostRemoveBatchRequest,
} from './types';

class SwipeRxWmsApiReplenishJob extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.REPLENISH_JOB);
    this.basePath = `/${this.apiResource}`;
  }

  async getReplenishJobBySkuCode(
    params: IGetReplenishJobBySkuCodeRequest,
  ): Promise<IGetReplinshJobBySkuCodeResponse> {
    const result = await this.get(`${this.basePath}`, params);
    if (result && result.data) {
      return result.data;
    }
    throw new Error('Failed to get replenish task');
  }

  async getSourceProductDetail(
    skuCode: string,
    sourceBin: Pick<ReplenishItemProps, 'bin' | 'inventory_number'>,
  ): Promise<ReplenishItemProps> {
    const query: IGetProductDetailRequest = {
      bin: sourceBin.bin,
      sku_code: skuCode,
      inventory_number: sourceBin.inventory_number,
    };
    const result = await this.get(
      `${this.basePath}/source/product-detail`,
      query,
    );
    if (result && result.data) {
      return result.data;
    }
    throw new Error('Failed to get source product detail');
  }

  async postPickSourceBin(
    skuCode: string,
    sourceBin: ReplenishItemProps,
  ): Promise<void> {
    const payload: IPostPickSourceBinRequest = {
      sku_code: skuCode,
      bin: sourceBin.bin,
      inventory_number: sourceBin.inventory_number,
      quantity: sourceBin.quantity,
      expiry_date: sourceBin.expiry_date,
    };
    await this.post(`${this.basePath}/source/pick`, payload);
  }

  async postRemoveSourceBatch(
    skuCode: string,
    sourceBin: Pick<ReplenishItemProps, 'bin' | 'inventory_number'>,
  ): Promise<void> {
    const payload: IPostRemoveBatchRequest = {
      sku_code: skuCode,
      bin: sourceBin.bin,
      inventory_number: sourceBin.inventory_number,
    };
    await this.post(`${this.basePath}/source/remove-batch`, payload);
  }

  async postPutDestinationBin(
    skuCode: string,
    destinationBin: ReplenishItemDestination,
  ): Promise<void> {
    const payload: IPostPutDestinationRequest = {
      sku_code: skuCode,
      bin: destinationBin.bin,
      inventory_number: destinationBin.inventory_number,
      quantity: destinationBin.quantity,
      expiry_date: destinationBin.expiry_date,
      is_revoked_bin: destinationBin.is_revoked_bin,
    };

    await this.post(`${this.basePath}/destination/put`, payload);
  }

  async postRemoveDestinationBatch(
    skuCode: string,
    destinationBin: Pick<ReplenishItemDestination, 'bin' | 'inventory_number'>,
  ): Promise<void> {
    const payload: IPostRemoveBatchRequest = {
      sku_code: skuCode,
      bin: destinationBin.bin,
      inventory_number: destinationBin.inventory_number,
    };
    await this.post(`${this.basePath}/destination/remove-batch`, payload);
  }

  async postCompleteReplenish(): Promise<boolean> {
    try {
      await this.post(`${this.basePath}/complete-replenish-item`, {});
      return true;
    } catch (error) {
      return false;
    }
  }
}

export const swipeRxWmsApiReplenishJob = new SwipeRxWmsApiReplenishJob();
