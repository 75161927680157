import { useLocation, useSearchParams } from 'react-router-dom';

import qs, { ParsedQuery } from 'query-string';

type FilterQuery = ParsedQuery;
type UpdateQueryParams = (
  queryParams: FilterQuery,
  options?: { clearOther?: boolean; clearQueryParams?: string[] },
) => void;
type UseUpdateQueryParams = () => {
  queryParams: FilterQuery;
  updateQueryParams: UpdateQueryParams;
};

export const useUpdateQueryParams: UseUpdateQueryParams = () => {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  let queryParams = qs.parse(location.search);

  const updateQueryParams: UpdateQueryParams = (newQueryParams, options) => {
    const { clearOther = false, clearQueryParams = [] } = options || {};
    if (newQueryParams) {
      queryParams = clearOther
        ? newQueryParams
        : {
            ...queryParams,
            ...newQueryParams,
            ...clearQueryParams.reduce<Record<string, null>>(
              (filterQuery, key) => {
                const newfilterQuery = { ...filterQuery, [key]: null };
                return newfilterQuery;
              },
              {},
            ),
          };

      setSearchParams(qs.stringify(queryParams, { skipNull: true }));
    }
  };

  return {
    queryParams,
    updateQueryParams,
  };
};
