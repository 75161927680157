import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import {
  IPickingJobPickedItemRequest,
  IPickingJobProductDetailRequest,
  IPickingJobProductDetailResponse,
  IPickingJobRecommendationRequest,
  IPickingJobRecommendationResponse,
  IPickingJobRemoveBatchRequest,
  IPickingJobResponse,
} from './types';

class SwipeRxWmsApiPickJob extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.PICKING_JOB);
    this.basePath = `/${this.apiResource}`;
  }

  async getPickJobs(): Promise<IPickingJobResponse[]> {
    const result = await this.get(`${this.basePath}`);
    if (result && result.data) {
      return result.data as IPickingJobResponse[];
    }
    return [];
  }

  async getRecommendation(
    params: IPickingJobRecommendationRequest,
  ): Promise<IPickingJobRecommendationResponse[]> {
    const result = await this.get(`${this.basePath}/recommendation`, params);
    if (result && result.data) {
      return result.data as IPickingJobRecommendationResponse[];
    }
    return [];
  }

  async getProductDetail(params: IPickingJobProductDetailRequest): Promise<{
    error?: string | true;
    data?: IPickingJobProductDetailResponse;
  }> {
    try {
      const result = await this.get(`${this.basePath}/product-detail`, params);
      const data = result.data as IPickingJobProductDetailResponse;
      return { data };
    } catch (error: unknown) {
      if (error instanceof Error) {
        return { error: error.message };
      }
      return { error: true };
    }
  }

  async postPickedItem(params: IPickingJobPickedItemRequest): Promise<boolean> {
    try {
      await this.post(`${this.basePath}/pick-item`, params);
      return true;
    } catch (error) {
      return false;
    }
  }

  async postRemoveBatch(params: IPickingJobRemoveBatchRequest): Promise<void> {
    try {
      await this.post(`${this.basePath}/remove-batch`, params);
    } catch (error) {
      // TODO send alert ?
    }
  }

  async postCompletePick(): Promise<boolean> {
    try {
      await this.post(`${this.basePath}/complete-pick-item`, {});
      return true;
    } catch (error) {
      return false;
    }
  }
}

export const swiperxWmsApiPickJob = new SwipeRxWmsApiPickJob();
