import React from 'react';

import { IPackingTask } from 'commons/types/packingTask.interface';
import { TableConfig } from 'commons/types/tableConfig.interface';

const formatElement = (
  input?: number[] | number | string,
  bold = false,
): React.JSX.Element => {
  const text = input?.toString();
  return <div style={{ fontWeight: bold ? '700' : '400' }}>{text}</div>;
};

export const poDataConfig: TableConfig = [
  {
    header: 'PoNumber',
    key: 'po_number',
  },
  {
    header: 'Basket(s)',
    element: (task: IPackingTask): React.ReactElement => {
      return formatElement(task.picking_task?.baskets);
    },
  },
  {
    header: 'Picker',
    element: (task: IPackingTask): React.ReactElement => {
      return formatElement(task.picking_task?.picker || '');
    },
  },
  {
    header: 'Total SKU',
    element: (task: IPackingTask): React.ReactElement => {
      return formatElement(task.picking_task?.total_sku, true);
    },
  },
  {
    header: 'Total QTY',
    element: (task: IPackingTask): React.ReactElement => {
      const totalQty = task.picking_task.total_qty;
      const pickActualQty = task.basket_infos.reduce((total, basketInfo) => {
        return (
          total +
          basketInfo.items.reduce((basketTotal, item) => {
            return basketTotal + item.total_qty_picked;
          }, 0)
        );
      }, 0);
      return formatElement(`${pickActualQty}/${totalQty}`);
    },
  },
];
