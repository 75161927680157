import { Grid, TextField } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { PRIMARY_RED } from 'commons/styles/colors';

class PickingListPageStyle extends CommonComponentStyle {
  SubtitleWrapper = styled(Grid)`
    padding: 8px 0 16px 0;
  `;

  Subtitle = styled(Grid)`
    font-size: 14px;
    font-weight: 700;
  `;

  SKUGridWrapper = styled(Grid)`
    background: ${this.colors.LIGHTER_GRAY};
    padding: 8px 0;
  `;

  SKUGrid = styled(Grid)`
    font-size: 14px;
    font-weight: 700;
    padding-top: 8px;
  `;

  SKUButtonGrid = styled(Grid)`
    text-align: right;
  `;

  TableInfoGridWrapper = styled(Grid)`
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  TableInfoGrid = styled(Grid)`
    padding: 8px 0;

    &.out-of-stock {
      color: ${PRIMARY_RED};
      font-size: 14px;
      display: inline-flex;
    }
    &.no-pad-top {
      padding-top: 0;
      padding-bottom: 16px;
    }
  `;

  ProgressSpan = styled.span`
    color: ${this.colors.PRIMARY_GRAY};
    margin-left: 8px;
  `;

  SearchBar = styled(TextField)`
    width: 100%;
    margin-bottom: 8px !important;
  `;
}

export default new PickingListPageStyle();
