/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { IPickItemActual } from 'commons/types';
import {
  IPackingTask,
  PackingTaskState,
} from 'commons/types/packingTask.interface';
import { ReducerKeys } from 'redux-stores/enums/reducerKey.enum';

const initialState: PackingTaskState = {
  packingTask: null,
};

const packingTaskSlice = createSlice({
  name: ReducerKeys.PackingTask,
  initialState,
  reducers: {
    setPackingTask(
      state: PackingTaskState,
      action: { type: string; payload: IPackingTask | null },
    ) {
      state.packingTask = action.payload;
    },
    checkItem(
      state: PackingTaskState,
      action: {
        type: string;
        payload: { basketIndex: number; itemIndex: number };
      },
    ) {
      const { payload } = action;
      if (state.packingTask) {
        state.packingTask.basket_infos[payload.basketIndex].items[
          payload.itemIndex
        ].check_passed =
          !state.packingTask.basket_infos[payload.basketIndex].items[
            payload.itemIndex
          ].check_passed;
      }
    },
  },
});

export const { setPackingTask, checkItem } = packingTaskSlice.actions;

export default packingTaskSlice.reducer;
