import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Header } from 'commons/components/Header';
import { Sidebar } from 'commons/components/Sidebar/Sidebar.component';
import { ListPageProps } from 'commons/types/listPageData.interface';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { BaseDashboardStyle as S } from '../styles';

export const ListPage: React.FC<React.PropsWithChildren<ListPageProps>> = (
  params,
) => {
  const {
    children,
    updateListSearchDebounce,
  }: React.PropsWithChildren<ListPageProps> = params;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams({
    page: '1',
    page_size: '50',
  });

  const { selectedLocation } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  useEffect(() => {
    updateListSearchDebounce(
      searchParams,
      selectedLocation?.warehouse_id,
      dispatch,
    );
  }, [dispatch, searchParams, updateListSearchDebounce, selectedLocation]);

  return (
    <S.FlexboxWrapper>
      <Header
        title="Dashboard"
        prevPageHandler={() => {
          navigate('/');
        }}
      />
      <S.DashboardWrapper>
        <Sidebar currentUrl={window?.location?.pathname} />
        <S.DashboardContentWrapper>
          <S.DashboardContentContainer>{children}</S.DashboardContentContainer>
        </S.DashboardContentWrapper>
      </S.DashboardWrapper>
    </S.FlexboxWrapper>
  );
};
