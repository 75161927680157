import { Grid } from '@mui/material';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  padding-top: 8px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  margin: 8px 16px;
`;

export const Text = styled.p`
  font-size: 14;
  margin: 0px 16px;
`;

export const FieldRowWrapper = styled(Grid)`
  margin: 12px 0;
`;

export const FooterWrapper = styled.div`
  text-align: center;
  padding: 16px;
`;

export const OKButton = styled.button`
  padding: 8px;
  margin: 0 8px;
  border-radius: 6px;
  border: 1px solid #1733e8;
  background: #1733e8;
  color: #fff;
  font-weight: 700;
  width: 40%;
`;

export const CancelButton = styled.button`
  padding: 8px;
  margin: 0 8px;
  border-radius: 6px;
  border: 1px solid #1733e8;
  background: #fff;
  color: #1733e8;
  font-weight: 700;
  width: 40%;
`;
