import {
  AnyAction,
  Dispatch,
  ThunkDispatch,
  UnknownAction,
} from '@reduxjs/toolkit';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { DEFAULT_PRODUCT_CONFIG } from 'commons/constants';
import { ReplenishType } from 'commons/enums';
import { PickingListBySKUs, UserState } from 'commons/types';
import { requestReplenish } from 'redux-stores/actions';
import { IRequestReplenishDTO } from 'services/apis/SwipeRxWmsApiReplenishList/types';

export const onRequestStocks = async (
  pickList: PickingListBySKUs,
  warehouseId: number,
  dispatch: ThunkDispatch<
    {
      pickingTask: any;
      utility: any;
      dashboard: any;
      replenishTask: any;
      auth: UserState;
    } & PersistPartial,
    undefined,
    UnknownAction
  > &
    Dispatch<UnknownAction | AnyAction>,
) => {
  const payload: IRequestReplenishDTO = {
    sku_code: pickList.sku_code,
    type: ReplenishType.PICKER_REQUEST,
  };

  const retailRecommendationBins = pickList.pick_recommendation.filter(
    (rec) => rec.bin_type === 'retail',
  );

  if (retailRecommendationBins.length > 0) {
    // get the least quantity from recommended bin
    const leastQtyIdx = retailRecommendationBins.reduce(
      (selectedIdx, rec, rIdx) => {
        if (rec.quantity < retailRecommendationBins[selectedIdx].quantity)
          return rIdx;
        return selectedIdx;
      },
      0,
    );
    const leastRecommendationBinQty = pickList.pick_recommendation[leastQtyIdx];
    const maxQuantity =
      (pickList.max_bin_qty || DEFAULT_PRODUCT_CONFIG.max_bin_qty) >=
      pickList.total_quantity
        ? pickList.max_bin_qty || DEFAULT_PRODUCT_CONFIG.max_bin_qty
        : pickList.total_quantity;

    payload.destination_bin_requested = [
      {
        bin: leastRecommendationBinQty.bin,
        bin_type: leastRecommendationBinQty.bin_type,
        warehouse_id: warehouseId,
        requested_quantity: maxQuantity - leastRecommendationBinQty.quantity,
      },
    ];
  }
  dispatch(requestReplenish(payload));
};
