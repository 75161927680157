import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

import { PRIMARY_RED } from 'commons/styles/colors';
import {
  setLoading,
  snackbarSetData,
} from 'redux-stores/reducers/utilityReducer';

/**
 * Default action flow. Set loading page, do function,
 * catch error and show in snackbar, set loading page
 */
export async function defaultActionProcess<T = void>(
  fx: () => Promise<T>,
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
  includeLoading: boolean = true,
): Promise<Awaited<T> | undefined> {
  if (includeLoading) dispatch(setLoading(true));
  let result;
  try {
    result = await fx();
  } catch (error: any) {
    dispatch(
      snackbarSetData({
        open: true,
        message: error.message,
        color: PRIMARY_RED,
      }),
    );
  }
  if (includeLoading) dispatch(setLoading(false));
  return result;
}
