import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import * as colors from 'commons/styles/colors';

class PackSummaryPageStyle extends CommonComponentStyle {
  ChildContentWrapper = styled(this.ContentWrapper)`
    overflow: visible;
  `;

  HeaderWrapper = styled.div`
    padding: 8px 0;
    text-align: center;
  `;

  Header = styled.p<{ color?: string }>`
    font-size: 18px;
    font-weight: 700;
    display: inline-flex;
    margin-bottom: 0;
    color: ${(props): string => props.color || colors.PRIMARY_GREEN};
  `;

  SubHeader = styled.p`
    margin-top: 0;
  `;

  SummaryWrapper = styled(Grid)`
    padding: 16px;
    box-shadow: 0px 0px 8px #0000001a !important;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 20px;
  `;

  SummaryName = styled.p`
    font-size: 16px !important;
    margin: 8px;
  `;

  SummaryValue = styled.p`
    font-size: 16px !important;
    font-weight: 700 !important;
    text-align: right !important;
    margin: 8px;
  `;

  ItemContentWrapper = styled(Grid)`
    border: 1px solid #0000001a;
    padding: 8px;
    border-radius: 4px;
  `;

  SubHeaderText = styled.p`
    font-weight: 700;
    font-size: 14px;
    color: ${this.colors.PRIMARY_GRAY};
  `;

  PriorityCard = styled.div`
    padding: 4px;
    border-radius: 8px;
    background: #fef3c7;
    color: ${colors.PRIMARY_YELLOW};
    font-weight: 700;
    width: 40px;
    text-align: center;
    font-size: 14px;
    height: fit-content;
  `;
}

export const PackSummaryStyle = new PackSummaryPageStyle();
