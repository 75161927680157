import React, { useState } from 'react';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, Checkbox, Grid, Stack, Typography } from '@mui/material';

import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import * as S from 'commons/components/Table/Table.styles';
import { LIGHT_GRAY, PRIMARY_BLUE, PRIMARY_GRAY } from 'commons/styles/colors';
import {
  DataTableProps,
  SortOrder,
} from 'commons/types/listPageData.interface';

export const DataTable: React.FC<React.PropsWithChildren<DataTableProps>> = (
  params,
): React.ReactNode => {
  const {
    children,
    handleCheckAll = (): void => {},
    checkAll = false,
    rowTitles = [],
    defaultSort,
  }: React.PropsWithChildren<DataTableProps> = params;

  const { updateQueryParams } = useUpdateQueryParams();
  const [sortOrders, setSortOrders] = useState<
    { field: string; order: SortOrder | null }[]
  >([]);

  const handleSortChange = (
    sortField: string,
    sortOrder: SortOrder | null,
  ): void => {
    setSortOrders([
      ...sortOrders
        .filter((order) => order.field !== sortField)
        .map((order) => ({ ...order, order: null })),
      { field: sortField, order: sortOrder },
    ]);
    updateQueryParams({
      sort_by: `${sortOrder ? sortField : defaultSort?.sortField}`,
      sort_order: `${sortOrder || defaultSort?.sortOrder}`,
    });
  };

  return (
    <S.TableWrapper
      style={{
        overflow: 'scroll-x',
      }}
    >
      <S.TableHeader>
        <tr>
          <S.TableHeaderRow>
            <Checkbox
              sx={{ zIndex: '0' }}
              onClick={handleCheckAll}
              checked={checkAll}
            />
          </S.TableHeaderRow>
          <S.TableHeaderRow />
          {rowTitles.map((title) => {
            const sortOrder =
              sortOrders.find((order) => order.field === title.sortField)
                ?.order || null;
            return (
              <S.TableHeaderRow key={title.title}>
                {title.sortField ? (
                  <Button
                    style={{ padding: '0', color: PRIMARY_GRAY }}
                    onClick={() => {
                      if (sortOrder) {
                        if (sortOrder === SortOrder.ASC) {
                          handleSortChange(
                            title.sortField || '',
                            SortOrder.DESC,
                          );
                        } else {
                          handleSortChange(title.sortField || '', null);
                        }
                      } else {
                        handleSortChange(title.sortField || '', SortOrder.ASC);
                      }
                    }}
                  >
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={10}>
                        <Typography variant="body2">{title.title}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Stack gap={0}>
                          <ArrowDropUp
                            style={{
                              color:
                                sortOrder === SortOrder.ASC
                                  ? PRIMARY_BLUE
                                  : LIGHT_GRAY,
                            }}
                            viewBox="4 4 14 14"
                            fontSize="small"
                          />
                          <ArrowDropDown
                            style={{
                              color:
                                sortOrder === SortOrder.DESC
                                  ? PRIMARY_BLUE
                                  : LIGHT_GRAY,
                            }}
                            viewBox="4 10 14 10"
                            fontSize="small"
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Button>
                ) : (
                  <Typography variant="body2">{title.title}</Typography>
                )}
              </S.TableHeaderRow>
            );
          })}
          <S.TableHeaderRow />
        </tr>
      </S.TableHeader>
      {children}
    </S.TableWrapper>
  );
};
