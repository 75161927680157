import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

import { PrintPDFTypeEnum } from 'commons/enums';
import { KoliInfoUI, OrderType } from 'commons/types';
import { IPackingTask } from 'commons/types/packingTask.interface';
import { printPDF } from 'commons/utils/printPackingTaskPDF.util';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

const PrintBtn: React.FC<{ orderType: string; packingTask: IPackingTask }> = ({
  orderType,
  packingTask,
}) => {
  const { company } = useSelector(
    (state: RootReducerInterface) => state.company,
  );
  const dispatch = useDispatch<AppDispatch>();

  const [open, setOpen] = React.useState(false);
  const [koli, setKoli] = useState<KoliInfoUI[]>([]);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const options = [
    { label: 'Print Invoice & TTF', key: PrintPDFTypeEnum.INVOICE_TTF },
    { label: 'Print Shipping Label', key: PrintPDFTypeEnum.SHIPPING_LABEL },
  ];

  if (
    orderType === OrderType.PRECURSOR &&
    packingTask.picking_task.is_digital_signed
  ) {
    options.push({ label: 'Print E-SP', key: PrintPDFTypeEnum.ESP });
  }

  useEffect(() => {
    const koliUI = packingTask.koli.map((k) => ({
      weight: k.weight.toString(),
    }));
    if (koliUI.length === 0) {
      koliUI.push({ weight: '1' });
    }
    setKoli(koliUI);
  }, [packingTask.koli]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="actions"
        style={{ marginLeft: '4px' }}
      >
        <Button style={{ padding: '5px 15px' }}>Actions</Button>
        <Button
          size="small"
          aria-controls={open ? 'print-btn-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select actions"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{ minWidth: '30px', width: '30px', padding: '0' }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="print-btn-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option.key}
                      onClick={() =>
                        printPDF(
                          packingTask,
                          company,
                          koli,
                          option.key,
                          dispatch,
                        )
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default PrintBtn;
