import React from 'react';

import { Close } from '@mui/icons-material';
import {
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarProps,
} from '@mui/material';

import { S } from './Snackbar.styles';

interface Props extends SnackbarProps {
  color: string;
  onClose?: () => void;
}

export const Snackbar: React.FC<Props> = ({
  color,
  message,
  onClose,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onCloseSnackbar = () => {
    if (onClose) onClose();
  };
  return (
    <MuiSnackbar onClose={onCloseSnackbar} {...props}>
      <S.Content
        data-testid="snackbar-content"
        color={color}
        message={message}
        action={[
          <IconButton
            data-testid="snackbar-close"
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <Close />
          </IconButton>,
        ]}
      />
    </MuiSnackbar>
  );
};
