import { Reducer } from 'redux';

import {
  CompanyState,
  DashboardState,
  PickingTaskState,
  ReplenishTaskState,
} from 'commons/types';
import { PackingTaskState } from 'commons/types/packingTask.interface';
import { ReducerKeys } from 'redux-stores/enums/reducerKey.enum';
import { UtilityState } from 'redux-stores/interfaces';

import companyInfoReducer from './companyInfoReducer';
import { dashboardReducer } from './dashboard.reducer';
import packingTaskReducer from './packingTaskReducer';
import pickingTaskReducer from './pickingTaskReducer';
import { replenishTaskReducer } from './replenishTaskReducer';
import utilityReducer from './utilityReducer';

export interface RootReducerInterface extends Record<ReducerKeys, unknown> {
  [ReducerKeys.PickingTask]: PickingTaskState;
  [ReducerKeys.Utility]: UtilityState;
  [ReducerKeys.Dashboard]: DashboardState;
  [ReducerKeys.ReplenishTask]: ReplenishTaskState;
  [ReducerKeys.PackingTask]: PackingTaskState;
  [ReducerKeys.Company]: CompanyState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer: Record<ReducerKeys, Reducer<any>> = {
  [ReducerKeys.ReplenishTask]: replenishTaskReducer,
  [ReducerKeys.PickingTask]: pickingTaskReducer,
  [ReducerKeys.Utility]: utilityReducer,
  [ReducerKeys.Dashboard]: dashboardReducer,
  [ReducerKeys.PackingTask]: packingTaskReducer,
  [ReducerKeys.Company]: companyInfoReducer,
};

export default rootReducer;
