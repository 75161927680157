import { createAsyncThunk } from '@reduxjs/toolkit';

import { PRIMARY_BLUE } from 'commons/styles/colors';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { swipeRxWmsApiAlertNotification } from 'services/apis/SwipeRxWmsApiAlertNotification';

export const getUserAlertNotification = createAsyncThunk(
  'utility/getUserAlertNotification',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiAlertNotification.getAlertNotification();
      if (data) {
        dispatch(
          snackbarSetData({
            open: true,
            message: data.message,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
    return undefined;
  },
);
