import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authReducer } from './features/authSlice';
import rootReducer from './reducers';

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth'],
};

const reducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: authReducer,
    ...rootReducer,
  }),
);

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
