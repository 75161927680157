import { Button } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class SelectTaskPageStyle extends CommonComponentStyle {
  SubTitle = styled.p`
    font-size: 14px;
    color: #747775;
    font-weight: 700;
    padding-top: 1.5rem;
  `;

  MenuOption = styled(Button)`
    margin: 8px 0 !important;
    width: 100%;
    border-radius: 10px !important;
    box-shadow: 1px 1px 5px #cecece;
    margin-top: 5px;
    background: #fff;
    height: 65px;
  `;

  MenuIcon = styled.img`
    margin-top: 6px;
    height: 45px;
  `;

  MenuText = styled.p`
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-left: 8px;
  `;
}

export default new SelectTaskPageStyle();
