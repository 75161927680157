/* eslint-disable no-unused-vars */

export enum UserRolesEnum {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  PICKER = 'picker',
  PICKER_SPV = 'picker-spv',
  PICKER_LEAD = 'picker-lead',
  PACKER = 'packer',
  PACKER_SPV = 'packer-spv',
  PACKER_LEAD = 'packer-lead',
  REPLENISH = 'replenish',
}
