import { createAsyncThunk } from '@reduxjs/toolkit';

import { PRIMARY_BLUE, PRIMARY_GREEN } from 'commons/styles/colors';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import {
  setActiveLocations,
  setAllOrderLists,
  setAllReplenishLists,
  setAllUserLists,
  setHighPriorityValue,
  setSummariesActiveOrder,
  setSummariesShifts,
} from 'redux-stores/reducers/dashboard.reducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { swiperxWmsApiDashboard } from 'services/apis/SwipeRxWmsApiDashboard';

export const getOrderListAction = createAsyncThunk(
  'dashboard/getOrderListAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;
    const queries: Record<string, string | string> = {};
    searchParams?.forEach((value, key) => {
      queries[key] = value;
    });

    if (locationId) queries.location = locationId;

    defaultActionProcess(async () => {
      const data = await swiperxWmsApiDashboard.getOrderListPaginated(queries);
      dispatch(setAllOrderLists(data));
    }, dispatch);
  },
);

export const getReplenishListAction = createAsyncThunk(
  'dashboard/getReplenishListAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;
    const queries: Record<string, string | string> = {};
    searchParams?.forEach((value, key) => {
      queries[key] = value;
    });

    if (locationId) queries.location = locationId;

    defaultActionProcess(async () => {
      const data =
        await swiperxWmsApiDashboard.getReplenishListPaginated(queries);
      dispatch(setAllReplenishLists(data));
    }, dispatch);
  },
);

export const getUserListAction = createAsyncThunk(
  'dashboard/getUserListAction',
  async (
    params: { searchParams?: URLSearchParams; locationId?: string },
    { dispatch },
  ) => {
    const { searchParams, locationId } = params;
    const queries: Record<string, string | string> = {};
    searchParams?.forEach((value, key) => {
      queries[key] = value;
    });

    if (locationId) queries.location = locationId;

    defaultActionProcess(async () => {
      const data = await swiperxWmsApiDashboard.getUserListPaginated(queries);
      dispatch(setAllUserLists(data));
    }, dispatch);
  },
);

export const getActiveOrderSummaries = createAsyncThunk(
  'dashboard/getActiveOrderSummaries',
  async (location: number | undefined, { dispatch }) => {
    defaultActionProcess(async () => {
      const data =
        await swiperxWmsApiDashboard.getActiveOrderSummaries(location);
      if (data) {
        dispatch(setSummariesActiveOrder(data));
      }
    }, dispatch);
  },
);

export const getShiftSummaries = createAsyncThunk(
  'dashboard/getShiftSummaries',
  async (location: number | undefined, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swiperxWmsApiDashboard.getShiftSummaries(location);
      if (data) {
        dispatch(setSummariesShifts(data));
      }
    }, dispatch);
  },
);

export const prioritizeOrderPick = createAsyncThunk(
  'dashboard/prioritizeOrderPick',
  async (payload: { poNumber: string; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumber, location } = payload;
      const success = await swiperxWmsApiDashboard.prioritizeOrderPick(
        poNumber,
        location,
      );

      if (success) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO ${poNumber} success!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO ${poNumber} cannot be prioritized`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkPrioritizeOrderPick = createAsyncThunk(
  'dashboard/bulkPrioritizeOrderPick',
  async (payload: { poNumbers: string[]; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkPrioritizeOrderPick(
        poNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO success! \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize PO success: \n ${result.success.join(
              '\n',
            )} \n while PO skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }

      // update state
      if (result.success.length > 0) {
        dispatch(
          setHighPriorityValue({ po_numbers: result.success, value: true }),
        );
      }
    }, dispatch);
  },
);

export const retryUpdateFailedOrder = createAsyncThunk(
  'dashboard/retryUpdateFailedOrder',
  async (payload: { poNumber: string; location?: number }, { dispatch }) => {
    const { poNumber, location } = payload;
    defaultActionProcess(async () => {
      const success = await swiperxWmsApiDashboard.retryUpdate(
        poNumber,
        location,
      );

      if (success) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit retry PO ${poNumber} done!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit retry PO ${poNumber} failed!`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkRetryUpdateFailedOrder = createAsyncThunk(
  'dashboard/bulkRetryUpdateFailedOrder',
  async (payload: { poNumbers: string[]; location?: number }, { dispatch }) => {
    defaultActionProcess(async () => {
      const { poNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkRetryUpdate(
        poNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry PO done! \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry PO done: \n ${result.success.join(
              '\n',
            )} \n while PO skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const getWMSActiveLocations = createAsyncThunk(
  'dashboard/getWMSActiveLocations',
  async (_: never, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swiperxWmsApiDashboard.getActiveLocations();
      if (data) {
        dispatch(setActiveLocations(data));
      }
    }, dispatch);
  },
);

export const prioritizeReplenishTask = createAsyncThunk(
  'dashboard/prioritizeReplenishTask',
  async (
    payload: { replenishNumber: string; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumber, location } = payload;
      const success = await swiperxWmsApiDashboard.prioritizeReplenishTask(
        replenishNumber,
        location,
      );

      if (success) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish ${replenishNumber} success!`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish ${replenishNumber} cannot be prioritized`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkPrioritizeReplenishTask = createAsyncThunk(
  'dashboard/bulkPrioritizeReplenishTask',
  async (
    payload: { replenishNumbers: string[]; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkPrioritizeReplenishTask(
        replenishNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish success!
                      \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Prioritize Replenish success: \n ${result.success.join(
              '\n',
            )} \n while Replenish skipped: \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }

      // update state
      if (result.success.length > 0) {
        dispatch(
          setHighPriorityValue({ po_numbers: result.success, value: true }),
        );
      }
    }, dispatch);
  },
);

export const retryUpdateFailedOrderReplenish = createAsyncThunk(
  'dashboard/retryUpdateFailedOrderReplenish',
  async (
    payload: { replenishNumber: string; location?: number },
    { dispatch },
  ) => {
    const { replenishNumber, location } = payload;
    defaultActionProcess(async () => {
      const success = await swiperxWmsApiDashboard.retryUpdateReplenish(
        replenishNumber,
        location,
      );

      if (success) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit retry Replenish Task ${replenishNumber} done!`,
            color: PRIMARY_GREEN,
          }),
        );
      }
    }, dispatch);
  },
);

export const bulkRetryUpdateFailedReplenish = createAsyncThunk(
  'dashboard/bulkRetryUpdateFailedReplenish',
  async (
    payload: { replenishNumbers: string[]; location?: number },
    { dispatch },
  ) => {
    defaultActionProcess(async () => {
      const { replenishNumbers, location } = payload;
      const result = await swiperxWmsApiDashboard.bulkRetryUpdateReplenish(
        replenishNumbers,
        location,
      );

      if (result.skipped.length === 0) {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry Replenish Task done!
            \n ${result.success.join('\n')}`,
            color: PRIMARY_GREEN,
          }),
        );
      } else {
        dispatch(
          snackbarSetData({
            open: true,
            message: `Submit Retry Replenish Task done: \n ${result.success.join(
              '\n',
            )} \n while Replenish Task skipped:
            \n ${result.skipped.join('\n')}`,
            color: PRIMARY_BLUE,
          }),
        );
      }
    }, dispatch);
  },
);
