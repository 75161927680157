import styled from 'styled-components';

class LoadingStyle {
  ModalWrapper = styled.div`
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.2);
  `;

  IconWrapper = styled.div`
    padding-top: 40vh;
    padding-left: 45vw;
  `;
}

export default new LoadingStyle();
