import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import AssignSOPage from 'pages/pick/assign-task/AssignTaskPage';
import PickItemPage from 'pages/pick/pick-item/PickItemPage';
import { PickSummaryPage } from 'pages/pick/pick-summary';
import PickingListPage from 'pages/pick/picking-list/PickingListPage';

export const usePickTaskRouter = (): React.JSX.Element => (
  <Route
    path="/pick-task"
    element={
      <PrivateRoute
        roles={[
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.ADMIN,
          UserRolesEnum.PICKER,
          UserRolesEnum.PICKER_LEAD,
        ]}
      />
    }
  >
    <Route path="/pick-task/assign" element={<AssignSOPage />} />
    <Route path="/pick-task/list" element={<PickingListPage />} />
    <Route path="/pick-task/item" element={<PickItemPage />} />
    <Route path="/pick-task/summary" element={<PickSummaryPage />} />
    <Route path="/pick-task" element={<Navigate to="/pick-task/assign" />} />
  </Route>
);
