import { Button } from '@mui/material';
import styled from 'styled-components';

import * as colorPallete from './colors';

class CommonComponentStyle {
  colors = colorPallete;

  ContentWrapper = styled.div`
    max-height: 90vh;
    overflow: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  `;

  PrimaryButton = styled(Button)`
    width: 85% !important;
    padding: 5px 8px !important;
    border-radius: 6px !important;
    border: 1px solid ${this.colors.PRIMARY_BLUE} !important;
    background: ${this.colors.PRIMARY_BLUE} !important;
    color: ${this.colors.PRIMARY_WHITE} !important;
    font-weight: 700 !important;
    &:disabled {
      border: 1px solid ${this.colors.LIGHT_GRAY} !important;
      background: ${this.colors.LIGHT_GRAY} !important;
    }
  `;

  SecondaryButton = styled(Button)`
    width: 85% !important;
    padding: 4px 8px !important;
    border-radius: 6px !important;
    border: 1px solid ${this.colors.PRIMARY_BLUE} !important;
    color: ${this.colors.PRIMARY_BLUE} !important;
    font-weight: 700 !important;
    background: ${this.colors.PRIMARY_WHITE} !important;
    &:disabled {
      border: 1px solid ${this.colors.LIGHT_GRAY} !important;
      color: ${this.colors.LIGHT_GRAY} !important;
      background: ${this.colors.LIGHTER_GRAY} !important;
      font-weight: 500 !important;
    }
  `;

  BlueButtonText = styled(Button)`
    border: none !important;
    font-weight: 700 !important;
    background: ${this.colors.PRIMARY_WHITE} !important;
    color: ${this.colors.PRIMARY_BLUE} !important;
  `;

  RedButtonText = styled(Button)`
    font-size: 12px !important;
    font-weight: 700 !important;
    color: ${this.colors.PRIMARY_RED} !important;
    border: none;
    background: ${this.colors.PRIMARY_WHITE} !important;
    &:disabled {
      color: ${this.colors.LIGHT_GRAY} !important;
    }
  `;

  FooterWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    background: ${this.colors.PRIMARY_WHITE};
    text-align: center;
    width: 100%;
    height: 50px;
    padding: 16px 0;
  `;

  BlankWhiteSpace = styled.div`
    height: 25vh;
    width: 100%;
  `;
}

export default CommonComponentStyle;
