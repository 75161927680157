import { createAsyncThunk } from '@reduxjs/toolkit';

import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { setCompanyInfo } from 'redux-stores/reducers/companyInfoReducer';
import { swipeRxWmsAPICompany } from 'services/apis/SwipeRxWmsApiCompany';

export const getCompanyInfo = createAsyncThunk(
  'companyInfo/getCompanyInfo',
  async (_, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPICompany.getUserCompanyInfo();
      if (response && response.data) {
        dispatch(setCompanyInfo(response.data));
      }
      return response.data;
    }, dispatch);
  },
);
