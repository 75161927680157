import React from 'react';

import { capitalize } from '@mui/material';

import { PickingRecommendation } from 'commons/types';
import { TableConfig } from 'commons/types/tableConfig.interface';
import { formatDate } from 'commons/utils/formatDate';

const formatText = (
  text: string,
  item: PickingRecommendation,
  alignRight = false,
): React.JSX.Element => {
  if (item.bin_type === 'retail' || item.is_preferred) {
    return (
      <div
        style={{ fontWeight: 'bold', textAlign: alignRight ? 'right' : 'left' }}
      >
        {text}
      </div>
    );
  }
  return <div style={{ textAlign: alignRight ? 'right' : 'left' }}>{text}</div>;
};

export const config: TableConfig = [
  {
    header: 'Bin',
    element: (item: PickingRecommendation): React.ReactElement => {
      return formatText(item.bin, item);
    },
  },
  {
    header: 'Batch',
    element: (item: PickingRecommendation): React.ReactElement => {
      return formatText(item.inventory_number, item);
    },
  },
  {
    header: 'Expiry Date',
    element: (item: PickingRecommendation): React.ReactElement => {
      const date = formatDate(item.expiry_date);
      return formatText(date, item);
    },
  },
  {
    header: 'Type',
    element: (item: PickingRecommendation): React.ReactElement => {
      const binTypeFirstText = capitalize((item.bin_type || '').split('_')[0]);
      return formatText(binTypeFirstText, item);
    },
  },
  {
    header: 'Qty',
    element: (item: PickingRecommendation): React.ReactElement => {
      return formatText(`${item.quantity}`, item, true);
    },
  },
];
