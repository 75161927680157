import React from 'react';

import { capitalize } from '@mui/material';

import { ReplenishBinRequestedWithReplenishedQuantity } from 'commons/types';
import { TableConfig } from 'commons/types/tableConfig.interface';

const formatText = (text: string, alignCenter = false): React.JSX.Element => {
  return (
    <div style={{ textAlign: alignCenter ? 'center' : 'left' }}>{text}</div>
  );
};

export const destinationConfig: TableConfig = [
  {
    header: 'Bin',
    element: (
      bin: ReplenishBinRequestedWithReplenishedQuantity,
    ): React.ReactElement => {
      return formatText(bin.bin || '-');
    },
  },
  {
    headerElement: formatText('Type BIN', true),
    element: (
      bin: ReplenishBinRequestedWithReplenishedQuantity,
    ): React.ReactElement => {
      return formatText(
        capitalize((bin.bin_type ?? '-').replace('_', ' ')),
        true,
      );
    },
  },
  {
    headerElement: formatText('QTY Requested', true),
    element: (
      bin: ReplenishBinRequestedWithReplenishedQuantity,
    ): React.ReactElement => {
      return formatText(bin.requested_quantity.toString(), true);
    },
  },
  {
    headerElement: formatText('QTY Replenished', true),
    element: (
      bin: ReplenishBinRequestedWithReplenishedQuantity,
    ): React.ReactElement => {
      return formatText(bin.replenished_quantity.toString() || '0', true);
    },
  },
];
