import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { capitalize, Divider } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import {
  CriteriaOption,
  SearchWithCriteria,
} from 'commons/components/SearchRelated';
import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { OrderSearchCriteriaEnum, OrderStatusEnum } from 'commons/enums';
import { getCompanyInfo } from 'redux-stores/actions/companyInfo.action';
import { getOrderListAction } from 'redux-stores/actions/dashboard.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { ListPage, ListFilterCard } from '../commons/components';

import OrderTable from './components/OrderTable.component';

const OrderListPage: React.FC = () => {
  const { updateQueryParams } = useUpdateQueryParams();

  const { orderList } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const dispatch = useDispatch<AppDispatch>();

  const updateOrderListSearchDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getOrderListAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  useEffect(() => {
    dispatch(getCompanyInfo());
  }, [dispatch]);

  const searchCriteria: CriteriaOption[] = [
    {
      name: 'PO',
      id: 'po_number',
      isArray: true,
      parameterMapping: 'po_numbers',
    },
    { name: 'Pharmacy ID', id: 'pharmacy_id' },
    {
      name: 'Baskets Number',
      id: 'baskets',
      isArray: true,
      parameterMapping: 'baskets',
    },
    { name: 'Picker', id: 'picker' },
    { name: 'Order Type', id: 'order_type' },
    { name: 'Priority', id: 'priority' },
  ];

  const pickingTaskStatusArrayText: {
    key: OrderSearchCriteriaEnum;
    text: string;
  }[] = [
    ...Object.values(OrderSearchCriteriaEnum).map((value) => ({
      key: value,
      text: capitalize(value.replace('-', ' ')),
    })),
  ];

  const [statusFilter, setStatusFilter] =
    useState<OrderSearchCriteriaEnum | null>(null);

  const handleStatusFilter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: OrderSearchCriteriaEnum,
  ): void => {
    setStatusFilter(value);
    if (value === OrderSearchCriteriaEnum.QUEUE) {
      updateQueryParams(
        { status: OrderStatusEnum.QUEUE, is_assigned: 'false' },
        { clearQueryParams: ['page'] },
      );
    } else if (value === OrderSearchCriteriaEnum.ASSIGNED) {
      updateQueryParams(
        { status: OrderStatusEnum.QUEUE, is_assigned: 'true' },
        { clearQueryParams: ['page'] },
      );
    } else {
      updateQueryParams(
        { status: value },
        { clearQueryParams: ['page', 'is_assigned'] },
      );
    }
  };

  return (
    <ListPage updateListSearchDebounce={updateOrderListSearchDebounce}>
      <ListFilterCard
        statusArrayText={pickingTaskStatusArrayText}
        statusFilter={statusFilter}
        handleStatusFilter={handleStatusFilter}
      >
        <SearchWithCriteria searchCriteriaOptions={searchCriteria} />
        <Divider variant="fullWidth" />
      </ListFilterCard>
      <OrderTable data={orderList} />
    </ListPage>
  );
};

export default OrderListPage;
