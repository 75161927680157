import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
  CancelTokenSource,
} from 'axios';
import { jwtDecode } from 'jwt-decode';

import { relogin } from 'redux-stores/features/authSlice';
import { store } from 'redux-stores/store';
import { KeycloakAPI } from 'services/keycloak';
import { KeycloakToken } from 'services/keycloak/interfaces';

import {
  QsStringifyParam,
  Query,
  SwipeRxAPIResources,
  IApiParams,
} from './types';

export abstract class SwipeRxWmsApi {
  private api: AxiosInstance;

  private cancelTokenSource: CancelTokenSource | null = null;

  protected readonly apiResource: SwipeRxAPIResources;

  constructor(apiResource: SwipeRxAPIResources) {
    this.api = SwipeRxWmsApi.createAPIServer({
      baseUrl: process.env.REACT_APP_WMS_API_BASE_URL || '',
    });
    this.apiResource = apiResource;
  }

  private static isTokenValid(expToken?: number): boolean {
    if (!expToken) return false;
    if (!expToken || expToken < Date.now() / 1000) return false;
    else return true;
  }

  private static async getToken(): Promise<string | null> {
    try {
      const tokenStr = localStorage.getItem('userToken');
      const token = tokenStr ? <KeycloakToken>JSON.parse(tokenStr) : null;

      if (!token) {
        return null;
      }

      if (!SwipeRxWmsApi.isTokenValid(token?.exp)) {
        let keycloakToken = null;
        console.log('accesToken expired need refresh');

        try {
          keycloakToken = await KeycloakAPI.refreshToken(token.refresh_token);
        } catch (ex) {
          // refresh token expired, need relogin
          console.log('refresh token exp, need relogin');
          store.dispatch(relogin());
        }

        if (!keycloakToken) {
          return null;
        }

        const exp = token.access_token
          ? jwtDecode(token.access_token).exp
          : undefined;
        console.log('set new token');
        const userToken: KeycloakToken = {
          access_token: keycloakToken.access_token,
          refresh_token: keycloakToken.refresh_token,
          id_token: keycloakToken.id_token,
          exp,
        };
        localStorage.setItem('userToken', JSON.stringify(userToken));

        return userToken.access_token;
      } else {
        return token.access_token;
      }
    } catch (ex) {
      return null;
    }
  }

  private static createAPIServer(params: IApiParams): AxiosInstance {
    const apiServerAxios = axios.create({
      baseURL: params.baseUrl,
    });

    apiServerAxios.interceptors.request.use(
      (config) =>
        SwipeRxWmsApi.getToken().then((accessToken) => {
          if (!accessToken) {
            throw new Error('No Authorization Token');
          }
          // eslint-disable-next-line no-param-reassign, dot-notation
          config.headers['Authorization'] = `Bearer ${accessToken}`;

          return config;
        }),
      // eslint-disable-next-line arrow-body-style
      (error) => {
        return Promise.reject(error);
      },
    );
    apiServerAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          // relogin
          console.log('relogin');
          store.dispatch(relogin());
        }

        return Promise.reject(error);
      },
    );

    return apiServerAxios;
  }

  private static throwError(e: any): void {
    if (e.response) {
      const { data } = e.response;
      if (Array.isArray(data.error)) throw data.error || data.message;
      if (typeof data.error === 'object') throw new Error(data.error.message);
      throw new Error(data.error || data.message);
    }
    throw new Error((e as Error).message);
  }

  protected cancelRequest(): void {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel();
    }
  }

  protected async get<T = any>(
    path: string,
    query?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { api: client } = this;

      this.cancelTokenSource = axios.CancelToken.source();

      const { data } = await client.get<T>(path, {
        ...config,
        cancelToken: this.cancelTokenSource.token,
        params: query,
      });

      return data;
    } catch (e) {
      console.error('@SwipeRxPt::get', (e as Error).message);
      throw SwipeRxWmsApi.throwError(e);
    }
  }

  protected async post<T = any>(
    path: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { api: client } = this;

      const { data } = await client.post<T>(path, payload, config);

      return data;
    } catch (e) {
      console.error('@SwipeRxPt::post', (e as Error).message);
      throw SwipeRxWmsApi.throwError(e);
    }
  }

  protected async patch<T = any>(
    path: string,
    payload: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { data } = await this.api.patch(path, payload, config);

      return data;
    } catch (e) {
      console.error('@SwipeRxPt::patch', (e as Error).message);
      throw SwipeRxWmsApi.throwError(e);
    }
  }

  protected async delete<T = any>(
    path: string,
    query?: any,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>['data']> {
    try {
      const { data } = await this.api.delete(path, {
        ...config,
        params: query,
      });

      return data;
    } catch (e) {
      throw SwipeRxWmsApi.throwError(e);
    }
  }
}
