import React, { useMemo, useState } from 'react';

import { Clear } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  capitalize,
} from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useDebouncedCallback } from 'use-debounce';

import { PRIMARY_GRAY } from 'commons/styles/colors';
import { ReplenishItemProps } from 'commons/types';

import { ReplenishTaskStyle as S } from '../ReplenishTask.style';

export interface IReplenishItemFormProps {
  index: number;
  item?: Partial<ReplenishItemProps>;
  type?: 'pick' | 'replenish';
  maxQuantityToAdd?: number;

  /** will be called if all field is filled */
  onAllFilled?: (index: number, newItem: ReplenishItemProps) => void;

  /** will be called on each update on the field */
  onChanged?: (
    index: number,
    newItem: Partial<ReplenishItemProps>,
  ) => Promise<void> | Promise<Partial<ReplenishItemProps> | undefined>;

  /** will be called on replace item */
  onReplace?: (index: number, newItem: Partial<ReplenishItemProps>) => void;
}

export const ReplenishItemForm: React.FC<IReplenishItemFormProps> = ({
  item,
  index,
  type,
  maxQuantityToAdd,
  onAllFilled,
  onChanged,
  onReplace,
}) => {
  const [_item, _setItem] = useState<Partial<ReplenishItemProps>>({
    bin: item?.bin,
    inventory_number: item?.inventory_number,
    quantity: item?.quantity,
    expiry_date: item?.expiry_date,
  });

  useMemo(() => {
    _setItem({
      bin: item?.bin,
      inventory_number: item?.inventory_number,
      quantity: item?.quantity,
      expiry_date: item?.expiry_date ? new Date(item.expiry_date) : undefined,
    });
  }, [item]);

  const _handlePartialFilledFields = async (
    _item: Partial<ReplenishItemProps>,
  ) => {
    if (
      onChanged &&
      _item &&
      item &&
      (_item.bin !== item.bin ||
        _item.inventory_number !== item.inventory_number ||
        _item.quantity !== item.quantity)
    ) {
      const newItem = await onChanged(index, _item);
      if (!newItem) {
        return;
      }
      _setItem({
        bin: newItem?.bin,
        inventory_number: newItem?.inventory_number,
        quantity: newItem?.quantity,
        expiry_date: newItem?.expiry_date
          ? new Date(newItem.expiry_date)
          : undefined,
      });
    }
  };

  const _handleFullyFilledFields = async (
    _item: Partial<ReplenishItemProps>,
  ) => {
    if (
      onAllFilled &&
      _item &&
      _item.bin &&
      _item.inventory_number &&
      _item.expiry_date &&
      _item.quantity &&
      (!item ||
        !(
          _item.bin === item.bin &&
          _item.inventory_number === item.inventory_number &&
          _item.expiry_date === item.expiry_date &&
          _item.quantity === item.quantity
        ))
    ) {
      onAllFilled(index, {
        bin: _item.bin,
        expiry_date: _item.expiry_date,
        inventory_number: _item.inventory_number,
        quantity: _item.quantity,
      });
    }
  };

  const debouncedFocusNextField = useDebouncedCallback((selectorId: string) => {
    const nextInput = document.querySelector<HTMLInputElement>(selectorId);
    if (nextInput) nextInput.focus();
  }, 500);

  const onFieldChange = (
    _type: keyof ReplenishItemProps,
    value: string | number | Date | null,
    nextField?: string,
  ): void => {
    let _newItem = structuredClone(_item);
    const prevValue = _newItem[_type];
    let newValue = typeof value === 'string' ? value.trim() : value;
    if (
      _type === 'quantity' &&
      value !== '' &&
      value !== undefined &&
      value !== '0'
    ) {
      const parsedValue =
        typeof value === 'string' ? parseInt(value, 10) : (value as number);
      if (parsedValue >= 0) {
        newValue =
          maxQuantityToAdd !== undefined && parsedValue >= maxQuantityToAdd
            ? maxQuantityToAdd
            : parsedValue;
      } else {
        newValue = 0;
      }
      _newItem = { ..._newItem, quantity: newValue };
    } else if (
      _type === 'quantity' &&
      (value === '' || value === undefined || value === '0')
    ) {
      _newItem = { ..._newItem, quantity: 0 };
      if (onReplace) {
        // to avoid call handlePartialFilledFields, return after remove batch
        onReplace(index, _newItem);
        _setItem(_newItem);
        return;
      }
    } else {
      _newItem = { ..._newItem, [_type]: newValue };
    }
    _setItem(_newItem);

    // check if field is partially filled
    // will trigger onChanged if do
    _handlePartialFilledFields(_newItem);

    // check if field is fully filled
    // will trigger onAllFilled to api
    _handleFullyFilledFields(_newItem);

    if (
      nextField &&
      value &&
      typeof value === 'string' &&
      (value.endsWith('\t') ||
        value.endsWith('\n') ||
        (!prevValue && value.length > 1))
    ) {
      debouncedFocusNextField(`input[id="field-pick-${nextField}-${index}"]`);
    }
  };

  return (
    <Grid container style={{ padding: '8px 0' }}>
      <Grid item xs={1} />
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs={5}>
            <p>Bin</p>
          </Grid>
          <S.InputWrapper item xs={7}>
            <TextField
              variant="outlined"
              placeholder="-"
              value={_item.bin || ''}
              onChange={(event): void =>
                onFieldChange('bin', event.target.value, 'batch')
              }
              id={`field-pick-bin-${index}`}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => onFieldChange('bin', '', 'batch')}
                    >
                      <Clear style={{ color: PRIMARY_GRAY }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { paddingRight: 0 },
              }}
            />
          </S.InputWrapper>
          <Grid item xs={5}>
            <p>Batch Number</p>
          </Grid>
          <S.InputWrapper item xs={7}>
            <TextField
              variant="outlined"
              placeholder="-"
              value={_item.inventory_number || ''}
              onChange={(event): void =>
                onFieldChange(
                  'inventory_number',
                  event.target.value,
                  'quantity',
                )
              }
              id={`field-pick-batch-${index}`}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        onFieldChange('inventory_number', '', 'quantity')
                      }
                    >
                      <Clear style={{ color: PRIMARY_GRAY }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { paddingRight: 0 },
              }}
            />
          </S.InputWrapper>
          <Grid item xs={5}>
            <p>Expiry Date</p>
          </Grid>
          <S.InputWrapper item xs={7}>
            <DateField
              variant="outlined"
              defaultValue={null}
              value={
                _item.expiry_date
                  ? DateTime.fromJSDate(_item.expiry_date)
                  : null
              }
              onChange={(value) => {
                onFieldChange('expiry_date', value?.toJSDate() || null);
              }}
              format="dd/MM/yyyy"
              formatDensity="spacious"
              id={`field-pick-expiry-${index}`}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => onFieldChange('expiry_date', '')}
                    >
                      <Clear style={{ color: PRIMARY_GRAY }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { paddingRight: 0 },
              }}
            />
          </S.InputWrapper>
          <Grid item xs={5}>
            <p>{type ? capitalize(type) : ''} Quantity</p>
          </Grid>
          <S.InputWrapper item xs={7}>
            <TextField
              variant="outlined"
              placeholder="0"
              value={_item.quantity || ''}
              type="number"
              onChange={(event): void =>
                onFieldChange('quantity', event.target.value)
              }
              id={`field-pick-quantity-${index}`}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onFieldChange('quantity', '0')}>
                      <Clear style={{ color: PRIMARY_GRAY }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { paddingRight: 0 },
              }}
            />
          </S.InputWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};
