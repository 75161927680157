import React from 'react';

import { CircularProgress } from '@mui/material';

import S from './Loading.styles';

interface LoadingProps {
  show: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ show }) => {
  const display = show ? 'block' : 'none';
  return (
    <S.ModalWrapper style={{ display }}>
      <S.IconWrapper>
        <CircularProgress color="primary" />
      </S.IconWrapper>
    </S.ModalWrapper>
  );
};
