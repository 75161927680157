/* eslint-disable class-methods-use-this */
import { PickingTask } from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import { IAddAssignedTaskRequest } from './interfaces';

class SwipeRxWmsApiPickList extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.PICKING_TASK);
  }

  // TODO return types
  async getPicklists() {
    try {
      const result = await this.get(`${this.apiResource}`, undefined);

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async assignPickingTask() {
    try {
      const result = await this.post(
        `${this.apiResource}/assign/user/new`,
        undefined,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async addPickingTask(payload: IAddAssignedTaskRequest): Promise<PickingTask> {
    try {
      const result = await this.post(
        `${this.apiResource}/assign/user/add`,
        payload,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async startPickingProcess(poNumbers: string[]) {
    try {
      const result = await this.post(`${this.apiResource}/start-pick`, {
        po_numbers: poNumbers,
      });

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async removePickingTask(poNumber: string) {
    try {
      const result = await this.patch(
        `${this.apiResource}/assign/${poNumber}`,
        undefined,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async assignBasket(pickListId: number, baskets: number[]) {
    // implementation here
    console.log(pickListId, baskets);
  }

  async updatePicklistItems(params: any) {
    console.log(params);
  }

  async updatePicklistStatus(params: any) {
    console.log(params);
  }
}

export const swipeRxWmsApiPickList = new SwipeRxWmsApiPickList();
