import { PrintPDFTypeEnum } from 'commons/enums';
import { PRIMARY_RED } from 'commons/styles/colors';
import { ICompanyInfo, KoliInfoUI, PickingTask } from 'commons/types';
import { IPackingTask } from 'commons/types/packingTask.interface';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { swipeRxWmsAPIPackingTask } from 'services/apis/SwipeRxWmsApiPackingTask';

import { mapInvoicePrintData } from './InvoicePrintDataMapper.util';
import { mapShippingLabelPrintData } from './ShippingLabelPrintDataMapper.util';

const _getPrintURL = (
  packingTask: IPackingTask,
  company: ICompanyInfo | null,
  koli: KoliInfoUI[],
  type: PrintPDFTypeEnum,
) => {
  switch (type) {
    case PrintPDFTypeEnum.INVOICE_TTF:
      return `/print/invoice-ttf?&skip-alert-notif=true`;
    case PrintPDFTypeEnum.SHIPPING_LABEL:
      return `/print/ship-label?koli=${JSON.stringify(
        koli,
      )}&skip-alert-notif=true&data=${encodeURIComponent(
        JSON.stringify(
          mapShippingLabelPrintData(packingTask.picking_task as PickingTask),
        ),
      )}`;
    default:
      return '/';
  }
};

export const printPDF = async (
  packingTask: IPackingTask,
  company: ICompanyInfo | null,
  koli: KoliInfoUI[],
  type: PrintPDFTypeEnum,
  dispatch: any,
) => {
  if (type === PrintPDFTypeEnum.ESP) {
    const url = await swipeRxWmsAPIPackingTask.getESPDocument(
      packingTask.po_number,
    );
    if (!url) {
      dispatch(
        snackbarSetData({
          open: true,
          message: 'Document URL not found for this task',
          color: PRIMARY_RED,
        }),
      );
    } else {
      const hrefArea = document.createElement('a');
      hrefArea.href = url;
      hrefArea.target = '_blank';
      hrefArea.click();
    }
  } else {
    // special case for invoice ttf. since data may large,
    // we store value temporarily in local storage
    // removed upon iframe destroyed
    if (type === PrintPDFTypeEnum.INVOICE_TTF) {
      localStorage.setItem(
        'invoice-ttf',
        JSON.stringify(mapInvoicePrintData(packingTask, company)),
      );
    }
    // trigger print
    const printedArea = document.createElement('iframe');
    printedArea.src = _getPrintURL(packingTask, company, koli, type);
    printedArea.hidden = true;
    document.body.appendChild(printedArea);
    setTimeout(() => {
      printedArea?.contentWindow?.focus();
      printedArea?.contentWindow?.print();
      setTimeout(() => {
        document.body.removeChild(printedArea);
        localStorage.removeItem('invoice-ttf');
      }, 2000);
    }, 1000);
  }
};
