import { TableConfig } from 'commons/types/tableConfig.interface';

export const config: TableConfig = [
  {
    header: 'SO Number',
    key: 'po_number',
  },
  {
    header: 'Bin',
    key: 'bin',
  },
  {
    header: 'QTY',
    key: 'quantity',
  },
];
