import React from 'react';
import { useSelector } from 'react-redux';

import { Header } from 'commons/components/Header';
import { selectUser } from 'redux-stores/features/authSlice';

import S from '../HomePage.styles';

interface IUserHeaderProps {
  title?: string;
  prevPageHandler?: () => void;
}

const UserInfoHeader: React.FC<IUserHeaderProps> = ({
  title,
  prevPageHandler,
}) => {
  const user = useSelector(selectUser);

  return (
    <>
      <Header title={title} prevPageHandler={prevPageHandler} />
      <S.SubTitle>
        {user.userName.toLocaleUpperCase()}
        {' | '}
        {user.warehouse?.toLocaleUpperCase()}
      </S.SubTitle>
    </>
  );
};

export default UserInfoHeader;
