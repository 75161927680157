/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Dialog } from '@mui/material';

import * as S from './SKUDialog.styles';
import { IAssignSKUModalProps } from './SKUModal.interface';

const AssignSKUDialog: React.FC<IAssignSKUModalProps> = ({
  onClose,
  openModal,
  onConfirm,
}) => {
  return (
    <Dialog onClose={onClose} open={openModal}>
      <S.TitleWrapper>
        <S.Title>ASSIGN SKU</S.Title>
      </S.TitleWrapper>

      <S.Text>
        You are about to request SKUs to be assigned to you. Request now?
      </S.Text>

      {/* Footer */}
      <S.FooterWrapper>
        <S.CancelButton onClick={onClose}>NO</S.CancelButton>
        <S.OKButton onClick={onConfirm}>YES</S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};

export default AssignSKUDialog;
