import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { CheckCircleOutline } from '@mui/icons-material';
import { Grid } from '@mui/material';

import ConfirmationDialog from 'commons/components/ConfirmationDialog/ConfirmationDialog.component';
import { Header } from 'commons/components/Header';
import { OrderStatusEnum } from 'commons/enums';
import * as colors from 'commons/styles/colors';
import {
  completePacking,
  getPackerOnProgressPackingTask,
} from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { PackSummaryStyle as S } from './PackSummary.style';

const PackSummaryPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const poNumber = searchParams.get('poNumber') ?? '';
    if (poNumber === '') {
      navigate('/pack-task/check-basket');
      return;
    }
    if (!packingTask) {
      dispatch(getPackerOnProgressPackingTask({ poNumber })).then(() =>
        setDataFetched(true),
      );
    }
  }, [packingTask, dispatch]);

  if (!packingTask && dataFetched) navigate('/pack-task/check-basket');
  if (!packingTask) return <div />;
  if (packingTask.picking_task.status !== OrderStatusEnum.PACKING) {
    navigate('/pack-task/check-basket');
    return <div />;
  }

  const handleOpenDialog = () => setOpenModal(true);

  const handleCloseDialog = () => setOpenModal(false);

  const onConfirm = () => {
    dispatch(completePacking({ navigate, poNumber: packingTask.po_number }));
  };

  const prevPageHandler = () => {
    navigate({
      pathname: `/pack-task/print/`,
      search: createSearchParams({
        poNumber: packingTask.po_number,
      }).toString(),
    });
  };

  return (
    <div>
      <Header title="Packing Summary" prevPageHandler={prevPageHandler} />
      <S.HeaderWrapper>
        <S.Header color={colors.PRIMARY_GREEN}>
          <CheckCircleOutline /> Packing Completed
        </S.Header>
        <S.SubHeader>Below is the summary of the task.</S.SubHeader>
      </S.HeaderWrapper>

      <S.ChildContentWrapper>
        <Grid container style={{ fontWeight: 700 }}>
          <Grid item xs={6}>
            PO Number
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            {packingTask?.po_number}
          </Grid>
        </Grid>
        <S.SummaryWrapper container>
          <Grid item xs={6}>
            <S.SummaryName>Invoice Number</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>
              {packingTask?.picking_task.invoice?.invoice_number || '-'}
            </S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Packer</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.packer}</S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Picker</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.picking_task?.picker}</S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>3PL</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>
              {packingTask?.picking_task.logistic_partner_name}
            </S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Priority Type</S.SummaryName>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.PriorityCard>
              {packingTask?.picking_task?.priority}
            </S.PriorityCard>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Koli Number</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.koli.length}</S.SummaryValue>
          </Grid>
          {/* Commented as currently no need to input weight for koli
              Might be needed in the future. Remove completely if the business
              flow is fixed to not use this section.
          */}
          {/* {packingTask && packingTask.koli.length > 0 ? (
            <S.ItemContentWrapper container>
              {packingTask?.koli.map((info, index) => (
                <>
                  <Grid item xs={6}>
                    <S.SummaryName>{`Koli ${index + 1}`}</S.SummaryName>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: 'right',
                      verticalAlign: 'middle',
                    }}
                  >
                    <S.SummaryValue>{`${info.weight} kg`}</S.SummaryValue>
                  </Grid>
                </>
              ))}
            </S.ItemContentWrapper>
          ) : null} */}
        </S.SummaryWrapper>
        <S.BlankWhiteSpace />
      </S.ChildContentWrapper>

      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={prevPageHandler}>
              GO BACK
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton onClick={handleOpenDialog}>
              COMPLETE TASK
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>

      <ConfirmationDialog
        title="Complete Task Confirmation"
        content="Are you sure to complete task?"
        onClose={() => handleCloseDialog()}
        openModal={openModal}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default PackSummaryPage;
