import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class ReplenishTaskPageStyle extends CommonComponentStyle {
  SubtitleWrapper = styled(Grid)`
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  `;

  Subtitle = styled(Grid)`
    font-weight: 700;
    font-size: 14px;
  `;

  ItemContentWrapper = styled(Grid)`
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    &.table-content {
      padding: 8px 16px;
      font-size: 14px;
    }
  `;

  SubHeaderText = styled.p`
    font-weight: 700;
    font-size: 14px;
    color: ${this.colors.PRIMARY_GRAY};
  `;

  InputWrapper = styled(Grid)`
    margin-top: 8px !important;
  `;
}

export const ReplenishTaskStyle = new ReplenishTaskPageStyle();
